import { axiosInstance as axios } from "../constants/constants";
import { IInviteUser } from "../interfaces/invite/invite";

export const GetRoles = async () => {
  try {
    const response: any = await axios.get("/roles");
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const GetInvitedUsers = async () => {
  try {
    const response: any = await axios.get("/invitation");
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const InviteUser = async (payload: IInviteUser) => {
  try {
    const response: any = await axios.post("/invitation", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const RemoveInvitedUser = async (id: string) => {
  try {
    const response: any = await axios.delete(`/invitation/${id}`);
    return response;
  } catch (err) {
    const { response } = err;
    throw response;
  }
};
