import React from "react";
import { Button } from "react-bootstrap";
import { IOutlineButton } from "../interfaces/outline-button";

const DefaultOutlineButton = ({ title, className, onClick, style }: IOutlineButton) => {
  return (
    <Button className={`outline-btn ${className}`} style={style} onClick={onClick}>
      {title}
    </Button>
  );
};

export default DefaultOutlineButton;
