import { axiosInstance as axios } from "../constants/constants";
import { ICustomField, ICustomFieldConfiguration } from '../interfaces/custom_fields/custom-fields';
import { showToast } from "../constants/constants";

export const CreateCustomKeys = async (payload: ICustomField) => {
  try {
    const response: any = await axios.post("/custom-field", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    if (error.statusCode === 409) {
      showToast.error(
        "Custom field indexes can not be same for " + payload.name,
        {
          className: "custom-toast"
        }
      );
    }
  }
};


export const CreateCustomConfigurationKeys = async (payload: ICustomFieldConfiguration) => {
  try {
    const response: any = await axios.post("/custom-field-configuration", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};


export const RemoveCustomConfigurationField = async (id: string) => {
  try {
    const response: any = await axios.delete(`/custom-field-configuration/${id}`);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const GetCustomConfigurationFields = async (dataSourceId: string) => {
  try {
    const response: any = await axios.get(`/custom-field-configuration?dataSourceId=${dataSourceId}`);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const UpdateCustomConfigurationKeys = async (payload: ICustomFieldConfiguration, id: string) => {
  try {
    const response: any = await axios.patch(`/custom-field-configuration/${id}`, payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const UpdateCustomKeys = async (payload: ICustomField, id: string) => {
  try {
    const response: any = await axios.patch(`/custom-field/${id}`, payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    if (error.statusCode === 409) {
      showToast.error(
        "Custom field indexes can not be same for " + payload.name,
        {
          className: "custom-toast"
        }
      );
    }
  }
};

export const RemoveCustomKeys = async (id: string) => {
  try {
    const response: any = await axios.delete(`/custom-field/${id}`);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};
