import React from "react";
import { IImport } from "../interfaces/button";
import { useHistory } from "react-router-dom";
import { RoutesLocationEnum } from '../interfaces/enum';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

const ImportButton = ({ title = 'Import', className = '', style, outline, showIcon }: IImport) => {
    const history = useHistory();

    const handleFiles = (e: any) => {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);

        reader.onload = (loadedEvent: any) => {
            let data = loadedEvent.target.result;

            // Grab our byte length
            let byteLength = data.byteLength;

            // Convert to conventional array, so we can iterate though it
            let ui8a = new Uint8Array(data, 0);

            // Used to store each character that makes up CSV header
            let csvHeaders: any = '';

            // Iterate through each character in our Array
            for (let i = 0; i < byteLength; i++) {
                // Get the character for the current iteration
                let char = String.fromCharCode(ui8a[i]);
                // Check if the char is a new line
                if (char && char.match(/[^\r\n]+/g) !== null) {
                    // Not a new line so lets append it to our header string and keep processing
                    csvHeaders += char;
                } else {
                    // We found a new line character, stop processing
                    break;
                }
            };

            history.push(RoutesLocationEnum.CSV_IMPORT, { csvHeaders: csvHeaders.split(','), file: files[0] });
        };
    }

    return (
        <label htmlFor="file-upload" className={`${outline ? 'custom-file-upload' : 'custom-file-upload-default'} ${className}`} style={style}>
            {
                showIcon ? (
                    <FontAwesomeIcon icon={faFileUpload} style={{ fontSize: 12 }} />
                ) : title
            }
            <input type='file' onChange={handleFiles} multiple={false} accept='.csv' className='custom-file-upload-input d-none' id='file-upload' />
        </label>
    );
};

export default ImportButton;
