import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { RoutesLocationEnum } from "../interfaces/enum";

const redirect = (path: string, location: any) => {
  return (
    <Redirect
      to={{
        pathname: path,
        state: { from: location }
      }}
    />
  );
};

export default function PublicRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
        return !token
          ? children
          : redirect(RoutesLocationEnum.DASHBOARD, location);
      }}
    />
  );
}
