import React, { useState, useEffect } from 'react';
import { Form, ListGroup, Modal, Spinner, Row, Col } from 'react-bootstrap';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../utilis/constants';
import DefaultInput from '../components/input-field.component';
import DefaultButton from '../components/button.component';
import { showToast } from '../constants/constants';
import { ForgetPassword } from '../services/auth';
import { UpdateProfile, GetLoggedInUserDetails } from '../services/users';
import { ConnectSlack, RemoveSlack } from '../services/slack';
import { RemoveDataSource } from '../services/data-source';
import { getLocalStorageItem, setLocalStorageItem } from '../utilis/helper';
import IDataSourcesList from '../interfaces/data_source/data-sources-list';
import { RoutesLocationEnum } from '../interfaces/enum';
import DefaultOutlineButton from '../components/default-outline-button.component';
import ImportButton from '../components/import-button-component';

const ProfilePage = () => {
  // state initialize
  const [modalShow, setModalShow] = useState('');
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [validated, setValidated] = useState(false);
  const [dataSources, setDataSources] = useState<IDataSourcesList[]>([]);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    slackStatus: false,
    slackInfo: {
      team: '',
      channel: '',
    },
  });
  // --

  const history = useHistory();
  const userFromLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
  let query = useLocation();
  const role = getLocalStorageItem(LOCAL_STORAGE_KEYS.ROLE);

  // request to server to get current LoggedInUser
  const getLoggedInUser = async () => {
    try {
      const loggedInUser = await GetLoggedInUserDetails();
      const search = new URLSearchParams(query.search);
      const slackAuthCode = search.get('code') || '';
      const { accounts = [] } = loggedInUser;

      if (accounts.length) {
        let registeredDataSources = accounts[0].dataSources || [];
        if (registeredDataSources.length) {
          accounts[0].dataSources = registeredDataSources.filter(
            (dataSource: IDataSourcesList) => dataSource.name === 'Salesforce'
          );
        }
      }

      setDataSources(loggedInUser.accounts[0].dataSources);
      setUser({
        firstName: loggedInUser.firstName,
        lastName: loggedInUser.lastName,
        phone: loggedInUser.phone,
        email: loggedInUser.email,
        slackStatus: loggedInUser.slackStatus,
        slackInfo: loggedInUser.slackInfo,
      });
      if (slackAuthCode) {
        connectToSlack(slackAuthCode);
      } else {
        setMainLoading(false);
      }
    } catch (err) {
      setMainLoading(false);
    }
  };

  // function to send an email to reset password.
  const sendEmail = async (ev: any) => {
    ev.preventDefault();
    try {
      setLoading(true);
      const loggedInUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
      await ForgetPassword({ email: loggedInUser.email });
      setLoading(false);
      showToast.success('Email has been sent successfully, Please Check', {
        className: 'custom-toast',
      });
    } catch (err) {
      setLoading(false);
      showToast.error(err, { className: 'custom-toast' });
    }
  };

  // function to  update profile
  const update = async (ev: any) => {
    ev.preventDefault();
    const form = ev.currentTarget;
    setValidated(true);
    if (!validatePhonenumber(user.phone)) {
      setPhoneValidation(true);
    }

    if (form.checkValidity() === false || !validatePhonenumber(user.phone)) {
      return false;
    }
    try {
      const response = await UpdateProfile(user);
      const { firstName, lastName, phone } = response;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, {
        ...userFromLocalStorage,
        firstName,
        lastName,
        phone,
      });
      setUser({ ...user, firstName, lastName, phone });
      showToast.success('Profile has been updated successfully', {
        className: 'custom-toast',
      });
    } catch (err) {
      showToast.error(err, { className: 'custom-toast' });
    }
  };

  const validatePhonenumber = (inputtxt: string) => {
    const phoneno = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  // method to remove data source from account
  const confirmRemoveDataSource = async (removeContacts: boolean) => {
    try {
      await RemoveDataSource(modalShow, removeContacts);
      history.push(RoutesLocationEnum.SETUP);
    } catch (err) {
      console.log(err, 'err========');
    }
  };

  // method to connect with slack
  const connectToSlack = async (slackAuthCode: string) => {
    try {
      const response = await ConnectSlack(slackAuthCode);
      setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, response);
      setUser({
        firstName: response.firstName,
        lastName: response.lastName,
        phone: response.phone,
        email: response.email,
        slackStatus: response.slackStatus,
        slackInfo: response.slackInfo,
      });
      setMainLoading(false);
      history.replace('/profile');
    } catch (err) {
      setMainLoading(false);
      console.log(err, '--err');
    }
  };

  // method to remove slack from account
  const removeSlack = async () => {
    try {
      setMainLoading(true);
      const response = await RemoveSlack();
      setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, response);
      setUser({
        firstName: response.firstName,
        lastName: response.lastName,
        phone: response.phone,
        email: response.email,
        slackStatus: response.slackStatus,
        slackInfo: response.slackInfo,
      });
      setMainLoading(false);
    } catch (err) {
      setMainLoading(false);
      console.log(err, '--err');
    }
  };

  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <div className="container-fluid public-route-container profile-page">
      <Modal
        show={modalShow !== ''}
        size="lg"
        centered={true}
        onHide={() => setModalShow('')}
      >
        <Modal.Header>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete Data Source with Data?</Modal.Body>
        <Modal.Footer>
          <Row className="justify-content-center">
            <Col md="4">
              <DefaultButton
                title={'Yes - Delete Data'}
                onClick={() => confirmRemoveDataSource(true)}
                style={{ width: '200px' }}
                hideLoader={true}
              />
            </Col>
            <Col md="4">
              <DefaultButton
                title="Yes - Keep Data"
                onClick={() => confirmRemoveDataSource(false)}
                style={{ width: '200px' }}
              />
            </Col>

            <Col md="4">
              <DefaultOutlineButton
                title="No**"
                onClick={() => setModalShow('')}
                style={{ width: '200px' }}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <div className="row align-items-center h-100">
        <div className="col-12">
          <div className="login-container">
            <Form
              validated={validated}
              noValidate={true}
              onSubmit={update}
              className="profile-form"
            >
              {!mainLoading ? (
                <React.Fragment>
                  <div className="logo">
                    <img
                      src={require('../assets/login-signup-logo.png')}
                      height="40"
                    />
                  </div>
                  <Form.Group controlId="formBasicFirstName">
                    <DefaultInput
                      placeHolder="First Name"
                      type="name"
                      value={user.firstName}
                      onChange={(ev) =>
                        setUser({ ...user, firstName: ev.target.value })
                      }
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter last name
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicLastName">
                    <DefaultInput
                      placeHolder="Last Name"
                      type="name"
                      value={user.lastName}
                      onChange={(ev) =>
                        setUser({ ...user, lastName: ev.target.value })
                      }
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter last name
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <DefaultInput
                      placeHolder="Email"
                      disable={true}
                      type="email"
                      value={user.email}
                      required={true}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPhone">
                    <DefaultInput
                      placeHolder="Phone ( ********** )"
                      type="phone"
                      value={user.phone}
                      onChange={(ev) => {
                        setUser({ ...user, phone: ev.target.value });
                        setPhoneValidation(false);
                      }}
                      required={true}
                      isInvalid={phoneValidation}
                      pattern="^\d{10}$"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter phone number in format: **********
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="login-btn">
                    <DefaultButton
                      title="Update"
                      style={{ width: '200px', marginBottom: '15px' }}
                      type="submit"
                      onClick={update}
                    />
                  </div>
                  <div className="login-btn">
                    <DefaultButton
                      title="Reset Password"
                      style={{ width: '200px', marginBottom: '15px' }}
                      disabled={loading}
                      onClick={sendEmail}
                    />
                  </div>

                  <div className="login-btn">
                    <DefaultButton
                      title="Invite Users"
                      style={{ width: '200px', marginBottom: '15px' }}
                      onClick={() => history.push(RoutesLocationEnum.INVITE)}
                    />
                  </div>

                  <div className="login-btn">
                    <ImportButton
                      style={{
                        width: '200px',
                        marginRight: '10px',
                        height: '35px',
                        padding: '0.300rem 1.25rem',
                      }}
                    />
                  </div>

                  <div className="login-btn">
                    <ListGroup className="data-source-list">
                      {role.name === 'Owner' ? (
                        dataSources.length ? (
                          dataSources.map(
                            (dataSource: IDataSourcesList, index) => (
                              <ListGroup.Item key={index}>
                                <span>{dataSource.name}</span>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{ fontSize: 16 }}
                                  onClick={() => setModalShow(dataSource.id)}
                                />
                                <br />
                                {dataSource.username && (
                                  <p className="text-muted mb-0 text-left">
                                    ({dataSource.username})
                                  </p>
                                )}
                              </ListGroup.Item>
                            )
                          )
                        ) : (
                          <ListGroup.Item>
                            <span>Add Data Source</span>
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{ fontSize: 16 }}
                              onClick={() => history.push('/setup')}
                            />
                          </ListGroup.Item>
                        )
                      ) : null}
                      {!user.slackStatus ? (
                        <ListGroup.Item>
                          <span>Add Slack</span>
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ fontSize: 16 }}
                            onClick={() =>
                              (window.location.href = process.env
                                .REACT_APP_SLACK_OAUTH_URL
                                ? process.env.REACT_APP_SLACK_OAUTH_URL
                                : 'https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=679942535749.1096630659605')
                            }
                          />
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item>
                          <span>Slack</span>
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ fontSize: 16 }}
                            onClick={() => removeSlack()}
                          />
                          <br />
                          <p className="text-muted mb-0 text-left">
                            Channel: ({user.slackInfo.channel})
                          </p>

                          <p className="text-muted mb-0 text-left">
                            Team: ({user.slackInfo.team})
                          </p>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <span className="w-75 text-left">
                          Global Custom Field Settings
                        </span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ fontSize: 16 }}
                          onClick={() =>
                            history.push(RoutesLocationEnum.CUSTOM_FIELDS_SETUP)
                          }
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span className="w-75 text-left">
                          Salesforce Custom Field Settings
                        </span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ fontSize: 16 }}
                          onClick={() =>
                            history.push(RoutesLocationEnum.CUSTOM_FIELDS)
                          }
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </React.Fragment>
              ) : (
                <div className="spinner-container">
                  <Spinner animation="grow" />
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
