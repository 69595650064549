import React from "react";
import { Row, Col } from "react-bootstrap";
import AlertIcon from "./alert-icon.component";
import IAlert from '../interfaces/alert';

const Alert = ({ icon, iconBgColor, title, onClick, textColor, style, onMouseEnter, onMouseOut, domainName, type }: IAlert) => {
  return (
    <Col
      md={4}
      xs={12}
      sm={6}
      xl={3}
      className='alert-column'
      onClick={onClick}
      style={style}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseOut}
    >
      <Row className='alert-row'>
        <AlertIcon
          icon={icon}
          color='#FFFFFF'
          bgColor={iconBgColor}
        />
        <Col className='alert-title-container'>
          {
            type === 3 ? (
              <span className='alert-title' style={{color: textColor}}>Remove this alert?</span>
            ) : (
              <span className='alert-title' style={{color: textColor}}>Another user from {type === 1 ? <span>{domainName}</span> : domainName} signs up</span>
            )
          }
        </Col>
      </Row>
    </Col>
  );
};

export default Alert;
