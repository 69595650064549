import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router";
import { showToast } from "../constants/constants";
import { ResetPassword } from "../services/auth";
import DefaultInput from "../components/input-field.component";
import DefaultButton from "../components/button.component";
import { RoutesLocationEnum } from "../interfaces/enum";

const ResetPasswordPage = () => {
  // state initialize
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(
    "Please retype password."
  );
  // --

  const history = useHistory();

  let query = useLocation();

  // function for reset password request to server
  const resetPassword = async (ev: any) => {
    ev.preventDefault();
    const form = ev.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) {
      return false;
    }
    if (confirmPassword !== password) {
      setValidated(false);
      setPasswordValidation("Passwords don't match");
    } else {
      try {
        const search = new URLSearchParams(query.search);
        const queryString = search.get("token") as string;
        setLoading(true);
        await ResetPassword({ password, code: queryString });
        showToast.success("Password has been changed successfully", {
          className: "custom-toast"
        });
        setLoading(false);
        setTimeout(() => {
          history.push(RoutesLocationEnum.DASHBOARD);
        }, 3000);
      } catch (err) {
        setLoading(false);
        let error = "";
        if (err.statusCode === 401) {
          error = err.message;
        }
        if (err.statusCode === 404) {
          error = "Invalid token found";
        }
        showToast.error(error, { className: "custom-toast" });
        setTimeout(()=>{
          history.push(RoutesLocationEnum.PROFILE)
        }, 3000)
      }
    }
  };
  return (
    <div className="container-fluid public-route-container">
      <div className="row align-items-center h-100">
        <div className="col-12">
          <div className="login-container">
            <Form
              onSubmit={resetPassword}
              validated={validated}
              noValidate={true}
            >
              <div className="logo">
                <img
                  src={require("../assets/login-signup-logo.png")}
                  height="40"
                />
              </div>
              <Form.Group controlId="formBasicPassword">
                <DefaultInput
                  placeHolder="New Password"
                  type="password"
                  value={password}
                  onChange={ev => {
                    setPassword(ev.target.value);
                    setPasswordValidation("Please retype password");
                  }}
                  minLength={8}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter password of minimum 8 characters.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword">
                <DefaultInput
                  placeHolder="Confirm New Password"
                  type="password"
                  value={confirmPassword}
                  onChange={ev => {
                    setConfirmPassword(ev.target.value);
                    setPasswordValidation("Please retype password");
                  }}
                  required={true}
                  isInvalid={passwordValidation === "Passwords don't match"}
                  minLength={8}
                />

                <Form.Control.Feedback type="invalid">
                  {passwordValidation}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="login-btn">
                <DefaultButton
                  title="Reset password"
                  onClick={(ev: any) => resetPassword(ev)}
                  disabled={loading}
                  style={{ width: "200px", marginBottom: "10px" }}
                  type="submit"
                />
                <p className="instructions">Please enter new password.</p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
