import { cloneDeep, differenceWith, findIndex, isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DefaultButton from '../components/button.component';
import DefaultCheckBox from '../components/checkbox-component';
import DefaultOutlineButton from '../components/default-outline-button.component';
import SearchBox from '../components/search-box.component';
import { INewAlert } from '../interfaces/alert';
import { IAlertMediumConfigurations } from '../interfaces/alert_modes/alert-modes';
import { IConditions } from '../interfaces/condition';
import { IContactKeys } from '../interfaces/contact-keys';
import { ConditionNameTypeEnum } from '../interfaces/enum';
import IUser from '../interfaces/user';
import IValidation from '../interfaces/validation';
import AlertSummary from '../pages/alert-summary';
import {
  CreateAlert,
  CreateAlertCondition,
  CreateAlertMode,
  GetAlerts,
  RemoveAlert,
  RemoveAlertCondition,
  RemoveAlertMode,
  UpdateAlert,
  UpdateAlertCondition,
} from '../services/alerts';
import { GetContactKeys } from '../services/contacts';
import { LOCAL_STORAGE_KEYS } from '../utilis/constants';
import { getLocalStorageItem } from '../utilis/helper';
const AlertsPage = () => {
  // state initialize
  const [showDetails, setShowDetails] = useState(false);
  const [recentlyAdded, setRecentlyAdded] = useState(false);
  const [alerts, setAlert]: any[] = useState([]);
  const [submitType, setSubmitType] = useState('Save');
  const [validationText, setValidationText] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [contactKeys, setContactKeys] = useState<IContactKeys[]>([]);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [alertSummary, setAlertSummary] = useState('');
  const location = useLocation();
  const [conditionTextValidator, setConditionTextValidator] = useState<
    IValidation[] | []
  >([]);

  const defaultAlert: INewAlert = {
    name: '',
    alertConditions: [],
    alertMediumConfigurations: [],
  };
  const [alert, updateAlert] = useState(defaultAlert);
  const loggedInUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
  const target = useRef(null);

  // state initialize end

  // function to add new condition for new alert
  const setAdded = () => {
    setRecentlyAdded(true);
    const element: IConditions = {
      operator: 'and',
      name: '',
      type: '',
      text: '',
    };
    const duplicatedAlert = cloneDeep(alert);
    duplicatedAlert.alertConditions.push(element);
    updateAlert(duplicatedAlert);
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
    setTimeout(() => {
      setRecentlyAdded(false);
    }, 1000);
  };

  // request to server to delete existing condition
  const removeConditionRequest = async (
    duplicatedAlert: INewAlert,
    value: IConditions
  ) => {
    const alertsClone = cloneDeep(alerts);
    try {
      await RemoveAlertCondition(value);
      const mainAlertObjIndex = findIndex(alerts, { id: alert.id });
      const mainAlertConditionIndex = findIndex(
        alerts[mainAlertObjIndex].alertConditions,
        { id: value.id }
      );
      alertsClone[mainAlertObjIndex].alertConditions.splice(
        mainAlertConditionIndex,
        1
      );
      updateAlert(duplicatedAlert);
      setAlert(alertsClone);
    } catch (error) {
      console.log('error : ', error);
    }
  };

  // function to remove the condition from alert which is about to create
  const removeElement = async (index: number, value: IConditions) => {
    const duplicatedAlert = cloneDeep(alert);
    const { alertConditions: duplicatedAlertConditions } = duplicatedAlert;
    if (value.id) {
      setOverlayLoading(true);
      await removeConditionRequest(duplicatedAlert, value);
    }
    duplicatedAlertConditions.splice(index, 1);
    if (
      duplicatedAlertConditions[0] &&
      duplicatedAlertConditions[0].operator !== 'and'
    ) {
      duplicatedAlertConditions[0].operator = 'and';
      if (duplicatedAlertConditions[0].id) {
        await UpdateAlertCondition(duplicatedAlertConditions[0]);
      }
    }
    setOverlayLoading(false);
    updateAlert(duplicatedAlert);
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
  };

  // function to set alert value that needs to be edited
  const editAlert = (value: INewAlert, index: number) => {
    setSubmitType('Update');
    updateAlert(value);
    setShowDetails(true);
    ensureInputsNotEmpty(alert.name, value.alertConditions);
  };

  // function to set the alert status whether AND/OR
  const setAlertStatus = (status: string, index: number) => {
    const duplicatedAlert = cloneDeep(alert);
    let updatedElements = duplicatedAlert.alertConditions;
    updatedElements[index].operator = status;
    duplicatedAlert.alertConditions = updatedElements;
    updateAlert(duplicatedAlert);
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
  };

  // --- function to get form values of alert
  const getFieldName = (
    ev: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const duplicatedAlert = cloneDeep(alert);
    const updatedElements = duplicatedAlert.alertConditions;
    updatedElements[index].name = ev.target.value;
    duplicatedAlert.alertConditions = updatedElements;
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
    updateAlert(duplicatedAlert);
  };

  const checkValidation = (ev: string, index: number, value: string) => {
    const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    const numberRegex = /^[+-]?\d+(\.\d+)?$/;
    let conditionValidator = [...conditionTextValidator];
    if (value === 'greater_than' || value === 'less_than') {
      if (!(dateRegex.test(ev) || numberRegex.test(ev))) {
        conditionValidator = [
          ...conditionTextValidator,
          {
            index,
            value: 'Require date(YYYY-MM-DD) or Number',
          },
        ];
        setConditionTextValidator(conditionValidator);
        return;
      }
    }

    conditionValidator = conditionValidator.filter((c) => c.index !== index);
    setConditionTextValidator(conditionValidator);
  };

  const getOperator = (
    ev: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    value: string
  ) => {
    checkValidation(value, index, ev.target.value);
    const duplicatedAlert = cloneDeep(alert);
    const updatedElements = duplicatedAlert.alertConditions;
    updatedElements[index].type = ev.target.value;
    duplicatedAlert.alertConditions = updatedElements;
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
    updateAlert(duplicatedAlert);
  };

  const getCondition = (
    ev: React.ChangeEvent<HTMLInputElement>,
    index: number,
    value: string
  ) => {
    checkValidation(ev.target.value, index, value);
    const duplicatedAlert = cloneDeep(alert);
    const updatedElements = duplicatedAlert.alertConditions;
    updatedElements[index].text = ev.target.value;
    duplicatedAlert.alertConditions = updatedElements;
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
    updateAlert(duplicatedAlert);
  };
  // ---

  // function to remove alerts that are already created
  const removeAlert = async (id: string, index: number) => {
    try {
      const updatedAlerts = [...alerts];
      setOverlayLoading(true);
      await RemoveAlert(id);
      updatedAlerts.splice(index, 1);
      if (id === alert.id) {
        updateAlert(defaultAlert);
        setShowDetails(false);
        setSubmitType(submitType === 'Update' ? 'Save' : 'Update');
      }
      setOverlayLoading(false);
      setAlert(updatedAlerts);
    } catch (error) {
      setOverlayLoading(false);
      console.log('error : ', error);
    }
  };

  // function to add or update new alert
  const saveAlert = async () => {
    const updatedAlerts = [...alerts];
    setOverlayLoading(true);
    // condition to check weather to update or create by checking id
    if (alert.id) {
      // name and conditions are required
      if (alert.name && alert.alertConditions.length) {
        try {
          const payload = {
            name: alert.name,
          };

          const updatedAlertIndex = findIndex(updatedAlerts, { id: alert.id });
          // check weather there is any new update in conditions
          const alteredCondtions = differenceWith(
            alert.alertConditions,
            alerts[updatedAlertIndex].alertConditions,
            isEqual
          );

          const alteredAlertModes = differenceWith(
            alert.alertMediumConfigurations,
            alerts[updatedAlertIndex].alertMediumConfigurations,
            isEqual
          );

          // multiple requests to server if there is multiple update in conditions
          if (alteredCondtions.length) {
            await Promise.all(
              alteredCondtions.map(async (condition) => {
                condition.alert = alert.id;
                // condition to check weather to post or patch by checking id
                if (condition.id) {
                  await UpdateAlertCondition(condition);
                } else {
                  await CreateAlertCondition(condition);
                }
              })
            ).then((updatedRes) => {
              console.log(updatedRes, 'updatedRes');
            });
          }

          if (alteredAlertModes.length) {
            await Promise.all(
              alteredAlertModes.map(async (alertMode) => {
                alertMode.alert = alert.id;
                // request for creating new alert
                await CreateAlertMode(alertMode);
              })
            ).then((updatedRes) => {
              console.log(updatedRes, 'updatedRes');
            });
          }

          const response = await UpdateAlert(alert.id, payload);
          updatedAlerts[updatedAlertIndex] = response;
          setModalShow(false);
          setAlert(updatedAlerts);
          updateAlert(defaultAlert);
          setShowDetails(false);
          setSubmitType('Save');
          setValidationText('');
          setOverlayLoading(false);
        } catch (error) {
          setOverlayLoading(false);
          console.log('error : ', error);
        }
      } else {
        setValidationText('Please provide complete details');
      }
    } else {
      // name and conditions are required
      if (alert.name && alert.alertConditions.length) {
        try {
          // request to create new alert with new conditions
          const response: INewAlert = await CreateAlert(alert);
          if (!response.alertConditions) {
            response.alertConditions = [];
          }
          updatedAlerts.push(response);
          setModalShow(false);
          setAlert(updatedAlerts);
          updateAlert(defaultAlert);
          setShowDetails(false);
          setSubmitType('Save');
          setValidationText('');
          setOverlayLoading(false);
        } catch (error) {
          console.log('error : ', error);
        }
      } else {
        setValidationText('Please provide complete details');
      }
    }
  };

  // function to get latest alerts
  const getAlerts = async () => {
    try {
      const response: any = await GetAlerts();
      const { alerts: alertResponse } = response;
      setAlert(alertResponse);
    } catch (error) {
      console.log('error : ', error);
    }
  };

  // function to get contact keys from server
  const getContactKeys = async () => {
    try {
      const response: IContactKeys[] = await GetContactKeys();
      setContactKeys(response);
    } catch (error) {
      console.log('error : ', error);
    }
  };

  // function to validate condition fields
  const ensureInputsNotEmpty = (name: string, condition: IConditions[]) => {
    const findInvalidConditions = condition.filter((value) => {
      return (
        !value.name ||
        value.name === ConditionNameTypeEnum.SELECT_PROPERTY ||
        !value.type ||
        value.type === ConditionNameTypeEnum.SELECT_OPERATOR ||
        !value.text
      );
    });
    if (name && !findInvalidConditions.length && condition.length) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  // function to get alert name from input field
  const getAlertName = (name: string) => {
    ensureInputsNotEmpty(name, alert.alertConditions);
    updateAlert({ ...alert, name });
  };

  // method to get user from local storage
  const getUserDetail = () => {
    const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    return { email: user.email, phone: user.phone };
  };

  // function to get value alert mode checkbox
  const getAlertMode = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const alertMode: IAlertMediumConfigurations = {
      broadcastMedium: ev.target.value,
    };
    const duplicatedAlert = cloneDeep(alert);
    const duplicateAlertModeIndex = findIndex(
      duplicatedAlert.alertMediumConfigurations,
      {
        broadcastMedium: ev.target.value,
      }
    );
    if (ev.target.checked) {
      duplicatedAlert.alertMediumConfigurations.push(alertMode);
    } else {
      const duplicateAlertMode =
        duplicatedAlert.alertMediumConfigurations.find(
          (singleAlertMode: IAlertMediumConfigurations) =>
            singleAlertMode.broadcastMedium === ev.target.value
        ) || ({} as IAlertMediumConfigurations);
      if (duplicateAlertMode.id) {
        try {
          setOverlayLoading(true);
          await RemoveAlertMode(duplicateAlertMode.id);
        } catch (err) {
          setOverlayLoading(false);
          console.log(err, 'err');
        }
      }
      duplicatedAlert.alertMediumConfigurations.splice(
        duplicateAlertModeIndex,
        1
      );
    }
    setOverlayLoading(false);
    updateAlert(duplicatedAlert);
    ensureInputsNotEmpty(alert.name, duplicatedAlert.alertConditions);
  };

  // function to decide weather checkbox should be check or not
  const checkAlertModeStatus = (value: string) => {
    const alertStatus = alert.alertMediumConfigurations.filter(
      (alertMode: IAlertMediumConfigurations) =>
        value === alertMode.broadcastMedium
    );
    if (alertStatus.length) {
      return true;
    } else {
      return false;
    }
  };

  // function  to reset form
  const resetAlertForm = async () => {
    setOverlayLoading(true);
    await getAlerts();
    setOverlayLoading(false);
    setShowDetails(false);
    updateAlert(defaultAlert);
    setValidationText('');
    setSubmitType('Save');
    setDisableBtn(true);
  };

  // function to check alerts right
  const checkAlertRights = (alertAdmin: IUser | undefined) => {
    return alertAdmin && alertAdmin.id === loggedInUser.id;
  };

  useEffect(() => {
    const { state } = location;
    // set default alert in form
    if (state) {
      // if the location is coming from result page then it'll set the pre built alert
      updateAlert(state as INewAlert);
      setShowDetails(true);
      setDisableBtn(false);
    }
    getAlerts();
    getContactKeys();
  }, [location]);

  return (
    <div className="alert-page">
      <Modal
        show={modalShow}
        centered={true}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to create an alert without alert medium, It would be
          considered as disabled
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton
            title={'Yes'}
            onClick={() => saveAlert()}
            style={{ width: '100px' }}
            hideLoader={true}
          />
          <DefaultOutlineButton
            title="No"
            onClick={() => setModalShow(false)}
            style={{ width: '100px' }}
          />
        </Modal.Footer>
      </Modal>

      <Row className="ml-2 mr-2">
        <Col md="12" xs="12" sm="12" lg="9" xl="6">
          <div className="table__container ">
            {overlayLoading && (
              <div className="centeredOverlay">
                <Spinner animation="grow" />
              </div>
            )}

            {!alertSummary ? (
              <React.Fragment>
                <Table className="alert-table" responsive={true}>
                  <thead>
                    <tr>
                      <td>Alert Name</td>
                      {alerts.length ? <td>Triggered Count</td> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {alerts.length ? (
                      alerts.map((value: INewAlert, index: number) => (
                        <tr key={index}>
                          <td>{value.name}</td>
                          <td
                            onClick={() => setAlertSummary(value.id)}
                            colSpan={!checkAlertRights(value.user) ? 3 : 0}
                          >
                            {value.alertSummariesCount}
                          </td>
                          {checkAlertRights(value.user) && (
                            <React.Fragment>
                              <td onClick={() => editAlert(value, index)}>
                                Edit
                              </td>
                              <td onClick={() => removeAlert(value.id, index)}>
                                Delete
                              </td>
                            </React.Fragment>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No alerts yet!</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div
                  className={`collapse__container ${showDetails ? 'show' : ''}`}
                >
                  <SearchBox
                    inputGroupClass="alert-title-field"
                    showCloseIcon={true}
                    showSearchIcon={false}
                    formOnChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => getAlertName(event.target.value)}
                    formOnFocus={() => null}
                    formSearchValue={alert.name}
                    reset={() => {
                      updateAlert({ ...alert, name: '' });
                    }}
                    inputPlaceHolder={'Alert name'}
                  />
                  {conditionTextValidator.length > 0 && (
                    <p className="ml-4 mt-3 text-danger">
                      - {conditionTextValidator[0].value}
                    </p>
                  )}
                  <div className="ml-4 mb-3 expandable-alert-div">
                    <p className="mt-2 mb-1 expandable-text">
                      <strong>Conditions</strong>
                    </p>
                    <p className="mb-3 expandable-text">
                      Define the criteria for this alert.
                    </p>

                    {alert.alertConditions.map(
                      (value: IConditions, index: number) => (
                        <React.Fragment key={index}>
                          <Row
                            className={`alert-svg-row ${
                              recentlyAdded &&
                              index + 1 === alert.alertConditions.length
                                ? 'collapse-show'
                                : ''
                            }`}
                            style={{ marginTop: -5 }}
                          >
                            <Col md="2" className="alert-type-con">
                              {index !== 0 ? (
                                <select
                                  className="property-dropdown toggle-alert-status"
                                  value={value.operator}
                                  onChange={(e) =>
                                    setAlertStatus(e.target.value, index)
                                  }
                                >
                                  <option value="and">And</option>
                                  <option value="or">Or</option>
                                </select>
                              ) : (
                                <div
                                  style={{
                                    width: 88,
                                    transform: 'translate(50%, 0px)',
                                  }}
                                  className="svg-line-desktop"
                                >
                                  <svg height="40" width="3">
                                    <line
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="200"
                                      className="indication__line"
                                    />
                                  </svg>
                                </div>
                              )}
                            </Col>

                            <Col
                              md="3"
                              className={`property-container alert-responsive-field`}
                            >
                              <select
                                className="property-dropdown"
                                value={value.name}
                                onChange={(e) => getFieldName(e, index)}
                              >
                                <option value="select-property">
                                  Select a property..
                                </option>
                                {contactKeys.map(
                                  (contact: IContactKeys, keyIndex: number) => (
                                    <option
                                      value={
                                        contact.custom
                                          ? contact.customKey
                                          : contact.key
                                      }
                                      key={keyIndex}
                                    >
                                      {contact.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </Col>
                            <Col md="2" className="alert-responsive-field">
                              <select
                                className="property-dropdown"
                                value={value.type}
                                onChange={(e) =>
                                  getOperator(
                                    e,
                                    index,
                                    value.text ? value.text : ''
                                  )
                                }
                              >
                                <option value="select-operator">
                                  Select..
                                </option>
                                {value.name === 'email' ? (
                                  <React.Fragment>
                                    <option value="equal_to">Equals</option>
                                    <option value="contains">Contains</option>
                                    <option value="does_not_contain">
                                      Does Not Contain
                                    </option>
                                    <option value="starts_with">
                                      Starts With
                                    </option>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <option value="equal_to">Equals</option>
                                    <option value="not_equal_to">
                                      Not Equal to
                                    </option>
                                    <option value="less_than">Less Than</option>
                                    <option value="greater_than">
                                      Greater Than
                                    </option>
                                    <option value="contains">Contains</option>
                                    <option value="does_not_contain">
                                      Does Not Contain
                                    </option>
                                    <option value="starts_with">
                                      Starts With
                                    </option>
                                  </React.Fragment>
                                )}
                              </select>
                            </Col>
                            <Col md="3" className="alert-responsive-field">
                              <InputGroup>
                                <FormControl
                                  ref={target}
                                  placeholder="Enter a condition.."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  className={`condition-input  ${
                                    conditionTextValidator.findIndex(
                                      (c) => c.index === index
                                    ) > -1 && 'invalid__field'
                                  }`}
                                  onChange={(
                                    ev: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    getCondition(
                                      ev,
                                      index,
                                      value.type ? value.type : ''
                                    )
                                  }
                                  value={value.text}
                                />
                              </InputGroup>
                            </Col>
                            <Col
                              md="2"
                              className="d-flex align-items-center alert-responsive-field"
                            >
                              <p
                                className="m-0 delete-text"
                                style={{ cursor: 'pointer' }}
                                onClick={() => removeElement(index, value)}
                              >
                                Delete
                              </p>
                            </Col>

                            <div className="svg-line-mobile" />
                          </Row>
                          <div
                            style={{
                              width: 88,
                              transform: 'translate(50%,-5px)',
                            }}
                            className="svg-line-desktop"
                          >
                            <svg height="20" width="3">
                              <line
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="200"
                                className="indication__line"
                              />
                            </svg>
                          </div>
                        </React.Fragment>
                      )
                    )}
                    <div className="w-100 alert-svg-row">
                      <button
                        onClick={() =>
                          alert.alertConditions.length < 5 ? setAdded() : null
                        }
                        className="button-transparent add-alert"
                        style={{ position: 'relative', bottom: '7px' }}
                      >
                        Add
                      </button>
                    </div>
                    <p className="mt-2 mb-3 expandable-text">
                      <strong>Send to...</strong>
                    </p>
                    {loggedInUser.slackStatus && (
                      <InputGroup className="mb-2">
                        <DefaultCheckBox
                          id="slack-checkbox"
                          onChange={getAlertMode}
                          className="alert-df-checkbox"
                          value="Slack"
                          checked={checkAlertModeStatus('Slack')}
                        />
                        <img
                          src={require('../assets/slack.png')}
                          width="20"
                          height="20"
                          style={{ objectFit: 'cover' }}
                        />
                        <span className="checkbox-span">slack</span>
                      </InputGroup>
                    )}
                    <InputGroup className="mb-2">
                      <DefaultCheckBox
                        id="email-checkbox"
                        onChange={getAlertMode}
                        className="alert-df-checkbox"
                        value="Email"
                        checked={checkAlertModeStatus('Email')}
                      />
                      <span className="checkbox-span font-dark-gray">
                        {getUserDetail().email}
                      </span>
                    </InputGroup>
                    <InputGroup>
                      <DefaultCheckBox
                        id="number-checkbox"
                        onChange={getAlertMode}
                        className="alert-df-checkbox"
                        value="Phone"
                        checked={checkAlertModeStatus('Phone')}
                      />
                      <span className="checkbox-span font-dark-gray">
                        {getUserDetail().phone}
                      </span>
                    </InputGroup>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <DefaultButton
                      title={showDetails ? submitType : 'Add'}
                      onClick={() => {
                        showDetails
                          ? alert.alertMediumConfigurations.length === 0
                            ? setModalShow(true)
                            : saveAlert()
                          : setShowDetails(true);
                      }}
                      disabled={
                        (disableBtn && showDetails) ||
                        conditionTextValidator.length > 0
                      }
                      hideLoader={true}
                      style={{ width: '140px' }}
                    />
                    {showDetails && (
                      <DefaultOutlineButton
                        title="Cancel"
                        onClick={() => resetAlertForm()}
                        style={{ width: '140px' }}
                      />
                    )}
                  </Col>
                  {validationText && (
                    <Col md="12" className="validation-text">
                      <p>* {validationText}</p>
                    </Col>
                  )}
                </Row>
              </React.Fragment>
            ) : (
              <AlertSummary
                backToAlerts={() => setAlertSummary('')}
                alertID={alertSummary}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AlertsPage;
