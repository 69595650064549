import { axiosInstance as axios } from "../constants/constants";
import { INewAlert } from "../interfaces/alert";
import { IConditions } from "../interfaces/condition";
import { IAlertMediumConfigurations } from "../interfaces/alert_modes/alert-modes";


export const GetAlerts = async () => {
    try {
      const response: any = await axios.get('/alerts');
      return response;
    } catch (err) {
      const { response } = err;
      throw response;
    }
};

export const CreateAlert = async (payload: INewAlert) => {
    try {
      const response: any = await axios.post("/alerts", payload);
      return response;
    } catch (err) {
      throw err;
    }
};

export const RemoveAlert = async (id: string) => {
  try {
    const response: any = await axios.delete(`/alerts/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const UpdateAlert = async (id: string, payload: any) => {
  try {
    const response: any = await axios.patch(`/alerts/${id}`, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export const CreateAlertCondition = async (payload: IConditions) => {
  try {
    const response: any = await axios.post('/alert-conditions', payload);
    return response;
  } catch (err) {
    console.log(err,'error')
    // throw err;
  }
};

export const GetAlertConditions = async (payload: string) => {
  try {
    const response: any = await axios.get(`/alert-conditions?name=email&text=${payload}&page=0&limit=10&`);
    return response;
  } catch (err) {
    console.log(err,'error')
    // throw err;
  }
};

export const UpdateAlertCondition = async (condition: IConditions) => {
  try {
    const response: any = await axios.patch(`/alert-conditions/${condition.id}`, condition);
    return response;
  } catch (err) {
    throw err;
  }
};

export const RemoveAlertCondition = async (condition: IConditions) => {
  try {
    const response: any = await axios.delete(`/alert-conditions/${condition.id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const CreateAlertMode = async (payload: IAlertMediumConfigurations) => {
  try {
    const response: any = await axios.post('/alert-medium-configurations', payload);
    return response;
  } catch (err) {
    console.log(err,'error')
    // throw err;
  }
};

export const RemoveAlertMode = async (id: string) => {
  try {
    const response: any = await axios.delete(`/alert-medium-configurations/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};