import React from "react";
import { ICheckBox } from "../interfaces/checkbox";

const DefaultCheckBox = ({ id, onChange, checked, className, value }: ICheckBox) => {
  return (
    <div className={`md-checkbox ${className}`}>
      <input id={id} type='checkbox' onChange={onChange} checked={checked} value={value} />
      <label htmlFor={id} />
    </div>
  );
};

export default DefaultCheckBox;
