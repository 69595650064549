import React, { useState, useEffect } from 'react';
import {
  Spinner,
  Table,
  Row,
  Col,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  GetDataSourceKeys,
  GetCustomFields,
  CompleteSetup,
} from 'src/services/data-source';
import { showToast } from '../constants/constants';
import { cloneDeep } from 'lodash';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IDataSourceKeys,
  ICustomFieldConfiguration,
  ICustomField
} from '../interfaces/custom_fields/custom-fields';
import Select from 'react-select';
import DefaultButton from '../components/button.component';
import { getLocalStorageItem } from '../utilis/helper';
import { LOCAL_STORAGE_KEYS } from '../utilis/constants';
import { CreateCustomConfigurationKeys, UpdateCustomConfigurationKeys, GetCustomConfigurationFields, RemoveCustomConfigurationField } from '../services/custom-fields';
import {
  RoutesLocationEnum,
} from '../interfaces/enum';

const CustomFieldsPage = () => {
  // state initialize
  const [loading, setLoading] = useState(false);
  const [selectedFields, updateSelectedFields] = useState<ICustomFieldConfiguration[]>([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableMainBtn, setDisableMainBtn] = useState(false);
  const [skipBtn, showSkipBtn] = useState('');
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  let [keys, setKeys] = useState<IDataSourceKeys[]>([]);
  let [indexes, updateIndexes] = useState(['1', '2', '3', '4']);
  const history = useHistory();
  const location = useLocation();
  // --

  // function to get custom fields that are already created
  const getCustomFieldsRecord = async () => {
    try {
      const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
      const userAccount = user.accounts[0];
      const dataSourceToken =
        userAccount.dataSources && userAccount.dataSources[0]
          ? userAccount.dataSources[0].id
          : '';
      const response = dataSourceToken
        ? await GetDataSourceKeys(dataSourceToken)
        : [];
      let customKeysResponse = await GetCustomFields();
      let customConfigurationKeysResponse = await GetCustomConfigurationFields(dataSourceToken);
      const dataSourceKeys = response.map((field: IDataSourceKeys) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
      if (!customKeysResponse.length) {
        setDisableMainBtn(true);
      }
      setKeys(dataSourceKeys);
      setLoading(false);
      setOverlayLoading(false);
      setCustomFields(customKeysResponse);
      updateSelectedFields(customConfigurationKeysResponse);
    } catch (err) {
      console.log(err, ': error');
    }
  };

  useEffect(() => {
    const { state } = location;
    const getKeys = async () => {
      setLoading(true);
      if (state) {
        showSkipBtn('Skip');
      }
      getCustomFieldsRecord();
    };
    getKeys();
  }, []);

  // functions to get form values
  const getCustomFields = (selectedField: any, index: number) => {
    const selectedFieldClone = cloneDeep(selectedFields);
    selectedFieldClone[index].dataSourceFieldName = selectedField.value;
    ensureFieldsNotEmpty(selectedFieldClone);
    updateSelectedFields(selectedFieldClone);
  };

  const getCustomFieldId = (customFieldId: string, index: number) => {
    const selectedFieldClone = cloneDeep(selectedFields);
    selectedFieldClone[index].customFieldId = customFieldId;
    ensureFieldsNotEmpty(selectedFieldClone);
    updateSelectedFields(selectedFieldClone);
  };
  // ----


  // function to validate newly or updated fields
  const ensureFieldsNotEmpty = (fields: ICustomFieldConfiguration[]) => {
    const findInvalidField = fields.filter((value) => {
      return (
        value.dataSourceFieldName === '' ||
        value.customFieldId === ''
      );
    });
    if (!fields.length) {
      setDisableBtn(false);
      setDisableMainBtn(true);
    } else {
      if (!findInvalidField.length) {
        setDisableBtn(false);
        setDisableMainBtn(false);
      } else {
        setDisableBtn(true);
        setDisableMainBtn(true);
      }
    }
  };

  // request to server for updating or creating custom field
  const createUpdateFieldRequest = async (field: ICustomFieldConfiguration) => {
    const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    const userAccount = user.accounts[0];
    const dataSourceToken =
      userAccount.dataSources && userAccount.dataSources[0]
        ? userAccount.dataSources[0].id
        : '';

    const payload = {
      dataSourceFieldName: field.dataSourceFieldName,
      customFieldId: field.customFieldId,
      dataSourceId: dataSourceToken
    };
    const response = !field.id
      ? await CreateCustomConfigurationKeys(payload)
      : await UpdateCustomConfigurationKeys(payload, field.id)
    return response;
  };

  // request to server for creating and updating custom fields.
  const createCustomFields = async () => {
    try {
      setOverlayLoading(true);
      await Promise.all(
        selectedFields.map(async (field: ICustomFieldConfiguration) => {
          const response = await createUpdateFieldRequest(field);
          return response;
        })
      ).then(async (updatedRes) => {
        const { state } = location;
        if (state) {
          showSkipBtn('Done');
        }
        await getCustomFieldsRecord();
        showToast.success('Custom Fields have been updated successfully', {
          className: 'custom-toast',
        });
      });
    } catch (error) {
      console.log(error, '---erra');
    }
  };

  // function to add new custom field item.
  const addNewCustomField = () => {
    const selectedFieldsClone = [...selectedFields];
    const newField = {
      dataSourceFieldName: '',
      customFieldId: '',
    };

    selectedFieldsClone.push(newField);
    ensureFieldsNotEmpty(selectedFieldsClone);
    updateSelectedFields(selectedFieldsClone);
  };

  // request to server for deleting custom fields
  const removeCustomFieldRequest = async (value: any, index: number) => {
    const duplicatedFields = cloneDeep(selectedFields);
    const possibleIndexes = [...indexes];
    try {
      setOverlayLoading(true);
      await RemoveCustomConfigurationField(value.id);
      const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
      const dataSourceToken = user.accounts[0].dataSources[0].id || '';
      const response = await GetDataSourceKeys(dataSourceToken);
      const dataSourceKeys = response.map((field: IDataSourceKeys) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
      duplicatedFields.splice(index, 1);
      possibleIndexes.push(value.index);
      setOverlayLoading(false);
      setKeys(dataSourceKeys);
      updateIndexes(possibleIndexes);
      updateSelectedFields(duplicatedFields);
      ensureFieldsNotEmpty(duplicatedFields);
    } catch (error) {
      console.log('error : ', error);
    }
  };

  // function  to remove custom field item and to remove fields that are already created.
  const deleteCustomField = async (value: ICustomFieldConfiguration, index: number) => {
    const duplicatedFields = cloneDeep(selectedFields);
    if (value.id) {
      removeCustomFieldRequest(value, index);
    } else {
      duplicatedFields.splice(index, 1);
      updateSelectedFields(duplicatedFields);
      ensureFieldsNotEmpty(duplicatedFields);
    }
  };

  // function to complete setup
  const completeConfSetup = async () => {
    try {
      const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
      const dataSourceToken = user.accounts[0].dataSources[0].id;
      await CompleteSetup(dataSourceToken);
      history.push(RoutesLocationEnum.DASHBOARD);
    } catch (error) {
      console.log('error : ', error);
    }
  };

  const nameToolTip = (props: any) => {
    return (
      <Tooltip {...props} show={props.show.toString()}>
        Salesforce field
      </Tooltip>
    );
  };

  const indexToolTip = (props: any) => {
    return (
      <Tooltip {...props} show={props.show.toString()}>
        Placement of field in table
      </Tooltip>
    );
  };

  const moveToDashboard = () => {
    const { state } = location;
    if (state) {
      completeConfSetup();
      showToast.success(
        'We are currently importing your contacts and they should be ready in 20 minutes',
        {
          className: 'custom-toast',
        }
      );
    }
  };

  return (
    <div className="customfields-page">
      {overlayLoading && (
        <div className="centeredOverlay">
          <Spinner animation="grow" />
        </div>
      )}
      <Row>
        <Col xs="12">
          {!loading ? (
            <React.Fragment>
              <Table bordered={true}>
                <thead>
                  <tr>
                    <th>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={nameToolTip}
                      >
                        <span>Default Alkemy Fields</span>
                      </OverlayTrigger>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="default-field-row">
                    <td className="customFieldNameCell">
                      <span>First Name</span>
                    </td>
                  </tr>
                  <tr className="default-field-row">
                    <td className="customFieldNameCell">
                      <span>Last Name</span>
                    </td>
                  </tr>
                  <tr className="default-field-row">
                    <td className="customFieldNameCell">
                      <span>Title</span>
                    </td>
                  </tr>
                  <tr className="default-field-row">
                    <td className="customFieldNameCell">
                      <span>Email</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5 className="main-heading">
                Salesforce Custom Fields ({' '}
                <span>
                  What other information do you want to send from Salesforce to
                  Alkemy?
                </span>{' '}
                ){' '}
              </h5>
              <Table bordered={true}>
                <thead>
                  <tr>
                    <th>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={nameToolTip}
                      >
                        <span>Salesforce Field</span>
                      </OverlayTrigger>
                    </th>
                    <th>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={indexToolTip}
                      >
                        <span>Custom Field / </span>
                      </OverlayTrigger>
                      <span onClick={() => history.push(RoutesLocationEnum.CUSTOM_FIELDS_SETUP)}>Create New Custom Field</span>
                    </th>
                    <th className="center-field-text">
                      <span
                        onClick={() =>
                          selectedFields.length < 4 && !disableBtn
                            ? addNewCustomField()
                            : null
                        }
                        className={
                          disableBtn || selectedFields.length >= 4
                            ? 'disable-add-field-btn'
                            : ''
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} color="white" />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFields.length ? (
                    selectedFields.map((field: ICustomFieldConfiguration, index) => {
                      return (
                        <tr key={index}>
                          <td className="customFieldNameCell">
                            {' '}
                            {field.id ? (
                              <span>{field.dataSourceFieldName}</span>
                            ) : (
                                <Select
                                  onChange={(ev) => getCustomFields(ev, index)}
                                  options={keys}
                                />
                              )}
                          </td>
                          <td>
                            <select
                              onChange={(ev) =>
                                getCustomFieldId(ev.target.value, index)
                              }
                              value={field.customFieldId}
                              className="index-dropdown"
                            >
                              <option value="select-index">
                                Select Custom Field
                              </option>
                              {customFields.map((customField: ICustomField, customFieldIndex: number) => {
                                return (
                                  <option
                                    key={customFieldIndex}
                                    value={customField.id}
                                  >
                                    {customField.label}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                          <td className="center-field-text">
                            <span
                              onClick={() => deleteCustomField(field, index)}
                            >
                              <FontAwesomeIcon icon={faTrash} color="white" />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                      <tr>
                        <td colSpan={4} className="no-field-found">
                          No Custom Fields Found
                      </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </React.Fragment>
          ) : (
              <div className="spinner-container">
                <Spinner animation="grow" />
              </div>
            )}
        </Col>

      </Row>
      {!loading ? (
        <React.Fragment>
          <DefaultButton
            title="Save"
            onClick={() => {
              createCustomFields();
            }}
            hideLoader={true}
            disabled={disableMainBtn}
          />
          {skipBtn && (
            <Link to="/" className="skip-btn" onClick={moveToDashboard}>
              {skipBtn}
            </Link>
          )}

        </React.Fragment>
      ) : null}
    </div>
  );
};

export default CustomFieldsPage;
