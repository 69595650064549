import { axiosInstance as axios } from "../constants/constants";
import { setLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import ILogin from "../interfaces/auth/login";
import IRegister from "../interfaces/auth/register";
import IResetPassword from "../interfaces/auth/reset-password";
import IForgetPassword from "../interfaces/auth/forget-password";
import IDataSourcesList from '../interfaces/data_source/data-sources-list';

export const Login = async (payload: ILogin) => {
  try {
    const response: any = await axios.post("/auth/login", payload);
    const { accessToken, user } = response;
    const { accounts = [] } = user;
    if (accounts.length) {
      let dataSources = accounts[0].dataSources || [];
      if (dataSources.length) {
        accounts[0].dataSources = dataSources.filter((dataSource: IDataSourcesList) => dataSource.name === 'Salesforce');
      }
    }
    setLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN, accessToken);
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return user;
  } catch (err) {
    const { response } = err;
    const { data } = response;
    let error = "";
    if (data.statusCode === 401) {
      error = data.message;
    }
    throw error;
  }
};

export const Register = async (payload: IRegister) => {
  try {
    const response: any = await axios.post("/auth/register", payload);
    const { accessToken, user } = response;
    setLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN, accessToken);
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return response;
  } catch (err) {
    const { response } = err;
    const { data } = response;
    let error = "";
    if (data.statusCode === 406 || data.statusCode === 409) {
      error = data.message;
    }
    throw error;
  }
};

export const InvitationRegister = async (payload: IRegister) => {
  try {
    const response: any = await axios.post("/invitation/register", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data } = response;
    let error = "";
    if (data.statusCode === 406) {
      error = data.message;
    }
    throw error;
  }
};

export const ForgetPassword = async (payload: IForgetPassword) => {
  try {
    const response: any = await axios.post("/auth/forgot-password", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data } = response;
    let error = "";
    if (data.statusCode === 401) {
      error = "No matching record is found with provided email";
    }
    if (data.statusCode === 409) {
      error = "Email has already been sent, Please check";
    }
    throw error;
  }
};

export const ResetPassword = async (payload: IResetPassword) => {
  try {
    const response: any = await axios.post("/auth/reset-password", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};
