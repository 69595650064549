import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "src/utilis/constants";
import SearchBox from "../components/search-box.component";
import { getLocalStorageItem } from "../utilis/helper";
import { RoutesLocationEnum } from "../interfaces/enum";
import ImportButton from '../components/import-button-component';

const LandingPage = () => {
  // state initialize
  const [searchKeyword, setSearchKeyword] = useState('');
  const [focusedSearch, setFocusedSearch] = useState(false);

  const history = useHistory();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    history.push(`${RoutesLocationEnum.RESULTS}?search=${searchKeyword}`);
  };

  // method to toggle form title on changing input value
  const renderFormTitle = (formTitle: boolean) => {
    const username = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    return (
      <React.Fragment>
        {formTitle ? (
          <p>
            These deals aren’t going to close themselves{' '}
            <span className='font-weight-bold'>{username.firstName}</span>, let’s start{' '}
            <span className='font-weight-bold'>Searching!</span>
          </p>
        ) : (
            <p>
              What are we gettin’ into today,{' '}
              <span className='font-weight-bold'>{username.firstName}</span>?
            </p>
          )}
      </React.Fragment>
    );
  };

  // search request api integration
  const getContacts = async (value: string) => {
    setSearchKeyword(value);
  };

  return (
    <div className={`landing-page page`}>
      <div className='content'>
        {renderFormTitle(focusedSearch)}
        <div className='landing-page-form-wrapper buttons d-flex'>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <SearchBox
              showCloseIcon={searchKeyword !== ''}
              showSearchIcon={focusedSearch}
              formOnFocus={() => setFocusedSearch(true)}
              formSearchValue={searchKeyword}
              inputGroupClass={
                focusedSearch ? 'search-focus' : 'default-search-width'
              }
              reset={() => {
                setFocusedSearch(false);
                setSearchKeyword('');
              }}
              search={() => {
                history.push(`${RoutesLocationEnum.RESULTS}?search=${searchKeyword}`);
              }}
              formOnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                getContacts(event.target.value);
              }}
              inputPlaceHolder={'Find Leads...'}
              showAll={true}
            />
            <Button
              className={`${
                focusedSearch ? 'animated-btn-width' : 'default-button-width'
                }`}
              onClick={() => history.push(RoutesLocationEnum.ALERTS)}
            >
              {focusedSearch ? (
                <FontAwesomeIcon icon={faBell} style={{ fontSize: 12 }} />
              ) : (
                  'Alerts'
                )}
            </Button>
            <ImportButton className={`${
              focusedSearch ? 'animated-btn-width' : 'default-button-width'
              }`} showIcon={focusedSearch}
              style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0'}}/>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
