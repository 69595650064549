import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { RoutesLocationEnum } from "../interfaces/enum";
import IDataSourcesList from '../interfaces/data_source/data-sources-list';

const redirect = (path: string, location: any) => {
  return (
    <Redirect
      to={{
        pathname: path,
        state: { from: location }
      }}
    />
  );
};

const checkSetupCompletion = (
  dataSource: IDataSourcesList,
  children: any,
  location: any
) => {
  if (dataSource.setup_completed) {
    return children;
  } else {
    if (RoutesLocationEnum.CUSTOM_FIELDS_SETUP === location.pathname) {
      return children;
    }
    return redirect(RoutesLocationEnum.CUSTOM_FIELDS, location);
  }
};

const checkDataSourceFieldExist = (
  dataSource: IDataSourcesList,
  children: any,
  location: any
) => {
  if (dataSource.dataSourceType !== null) {
    return checkSetupCompletion(dataSource, children, location);
  } else {
    return redirect(RoutesLocationEnum.DATA_SOURCE_FIELD, location);
  }
};

const checkDataSourceExist = (
  accounts: any[],
  children: any,
  location: any
) => {
  if (!accounts[0].dataSources || !accounts[0].dataSources.length) {
    return children;
    // return redirect(RoutesLocationEnum.SETUP, location);
  } else {
    const dataSources = accounts[0].dataSources;
    const isDataSourceAvailable = dataSources.find((dataSource: any) => dataSource.name === 'Salesforce');
    if (!isDataSourceAvailable) {
      return children;
    } else {
      const { state } = location;
      if (state && state.register) {
        return redirect(RoutesLocationEnum.CUSTOM_FIELDS, location);
      } else {
        return checkDataSourceFieldExist(
          isDataSourceAvailable,
          children,
          location
        );
      }
    }
  }
};

const checkAccountExist = (accounts: any[], children: any, location: any) => {
  if (accounts.length) {
    return checkDataSourceExist(accounts, children, location);
  } else {
    return redirect(RoutesLocationEnum.ACCOUNT, location);
  }
};

const checkToken = (
  token: string,
  accounts: any[],
  children: any,
  location: any
) => {
  if (token) {
    return checkAccountExist(accounts, children, location);
  } else {
    return redirect(RoutesLocationEnum.LOGIN, location);
  }
};

export default function PrivateRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
        const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER) || {};
        const { accounts = [] } = user;
        return checkToken(token, accounts, children, location);
      }}
    />
  );
}
