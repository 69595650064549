import React, { useState, useEffect } from "react";
import { Navbar, Form } from "react-bootstrap";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { removeLocalStorageItem, getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import SearchBox from "./search-box.component";
import SearchResultContext from "../store/store";
import { RoutesLocationEnum } from "../interfaces/enum";

const Header = () => {
  const location = useLocation()["pathname"];
  const [searchKeyword, setSearchKeyword] = useState("");
  const [focusedSearch, setFocusedSearch] = useState(false);
  const [dropDownDisplay, setDropDownDisplay] = useState(false);
  const [userName, setUserName] = useState("");
  const history = useHistory();

  const logout = () => {
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    history.replace(RoutesLocationEnum.LOGIN);
  };

  const navigateToHome = () => {
    const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (token) {
      history.push(RoutesLocationEnum.DASHBOARD);
    } else {
      history.push(RoutesLocationEnum.LOGIN);
    }
  };

  const submitSearchValue = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDropDownDisplay(false);
    let searchString = `${RoutesLocationEnum.RESULTS}?search=${searchKeyword}`;
    const search = new URLSearchParams(query.search);
    const alertSummaryCode = search.get("alertSummary") || "";
    if (alertSummaryCode) {
      searchString += `&alertSummary=${alertSummaryCode}`;
    }
    history.push(searchString);
  };

  let query = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(query.search);
    const queryString = search.get("search");
    if (queryString) {
      setSearchKeyword(queryString);
    } else {
      setSearchKeyword("");
    }
  }, [query]);

  useEffect(() => {
    const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    if (user) {
      setUserName(user.firstName);
    }
  });

  const lessSecureRoutes = [
    RoutesLocationEnum.ACCOUNT,
    RoutesLocationEnum.DATA_SOURCE_FIELD,
    RoutesLocationEnum.CUSTOM_FIELDS
  ];

  return (
    <Navbar collapseOnSelect={true} expand="lg">
      <Navbar.Brand onClick={() => navigateToHome()}>
        <img src={require("../assets/logo-brand4x.png")} />
      </Navbar.Brand>
      {location === RoutesLocationEnum.RESULTS ||
      location === RoutesLocationEnum.ALERTS ? (
        <React.Fragment>
          <Form
            autoComplete="off"
            className="flex-1 d-flex header-search"
            onSubmit={submitSearchValue}
          >
            <SearchBox
              formControlClass={searchKeyword ? "autocomplete-input" : ""}
              inputGroupClass="header-input"
              showCloseIcon={dropDownDisplay}
              showSearchIcon={focusedSearch}
              formOnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchKeyword(event.target.value);
                setDropDownDisplay(true);
              }}
              formOnFocus={() =>
                location === RoutesLocationEnum.RESULTS
                  ? setFocusedSearch(true)
                  : history.push(RoutesLocationEnum.DASHBOARD)
              }
              formSearchValue={searchKeyword}
              reset={() => {
                setFocusedSearch(false);
                setDropDownDisplay(false);
                setSearchKeyword("");
              }}
              inputPlaceHolder={""}
              search={e => submitSearchValue(e)}
            />
            {location === RoutesLocationEnum.RESULTS ? (
              <React.Fragment>
                <SearchResultContext.Consumer>
                  {({ records_length, response_time }) => (
                    <React.Fragment>
                      <div className="record-length">
                        <span>{records_length}</span>People
                      </div>
                      <div className="record-time">
                        ({response_time} seconds)
                      </div>
                    </React.Fragment>
                  )}
                </SearchResultContext.Consumer>
              </React.Fragment>
            ) : null}
          </Form>
        </React.Fragment>
      ) : null}
      {location === RoutesLocationEnum.RESULTS ||
      location === RoutesLocationEnum.ALERTS ||
      location === RoutesLocationEnum.DASHBOARD ||
      location === RoutesLocationEnum.PROFILE ||
      location === RoutesLocationEnum.CUSTOM_FIELDS ||
      location === RoutesLocationEnum.SETUP ||
      location === RoutesLocationEnum.DATA_SOURCE_FIELD ||
      location === RoutesLocationEnum.ACCOUNT ||
      location === RoutesLocationEnum.INVITE ||
      location === RoutesLocationEnum.CUSTOM_FIELDS_SETUP ||
      location === RoutesLocationEnum.CSV_IMPORT ? (
        <React.Fragment>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <Navbar.Text>
              <a>Welcome, {userName}</a>
            </Navbar.Text>
            <Navbar.Text>
              <div className="dropdown">
                <a className="avatar">
                  <img
                    src={require("../assets/avatar.png")}
                    alt="Avatar"
                    className="avatar"
                  />
                </a>
                <div className="dropdown-content">
                  {lessSecureRoutes.indexOf(location) === -1 ? (
                    <a onClick={() => history.push(RoutesLocationEnum.PROFILE)}>
                      Settings
                    </a>
                  ) : null}
                  <a onClick={() => logout()}>Logout</a>
                </div>
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Navbar.Text>
            <a className="avatar" onClick={() => logout()}>
              <img
                src={require("../assets/avatar.png")}
                alt="Avatar"
                className="avatar hide-avatar"
              />
            </a>
          </Navbar.Text>
        </React.Fragment>
      )}
    </Navbar>
  );
};

export default Header;
