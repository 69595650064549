import React from "react";
import IInput from "../interfaces/input-field";
import { Form } from "react-bootstrap";

const DefaultInput = ({ type, onChange, placeHolder, value, required, isInvalid, minLength, disable, pattern, onBlur, onFocus }: IInput) => {
    return (
        <Form.Control minLength={minLength} isInvalid={isInvalid} type={type} placeholder={placeHolder} className='default-input-field' value={value} onChange={onChange} required={required} disabled={disable} pattern={pattern} onBlur={onBlur} onFocus={onFocus}/>
    );
};

export default DefaultInput;
