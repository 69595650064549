import { axiosInstance as axios } from "../constants/constants";
import {
  setLocalStorageItem,
  getLocalStorageItem
} from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import IUser from "../interfaces/user";
import IAccount from "../interfaces/auth/account";

export const AddAccount = async (payload: IAccount) => {
  try {
    const response: any = await axios.post("/accounts", payload);
    let user: IUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    if (user.accounts && !user.accounts.length) {
      delete response.user;
      user.accounts.push(response);
    } else {
      delete response.user;
      user.accounts = [response];
    }
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};
