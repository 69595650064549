import { axiosInstance as axios } from "../constants/constants";
import { ISearchResult } from "../interfaces/search-result";

export const GetSearchRecords = async (
  domain: string,
  page: string,
  sortQuery: string,
  rowsPerPage: string,
  alertSummaryCode?: string
) => {
  try {
    let searchQuery = `/contacts?dataSourceName=Salesforce&domain=${domain}&page=${page}&limit=${rowsPerPage}${sortQuery}`;
    if (alertSummaryCode) {
      searchQuery += `&alertSummary=${alertSummaryCode}`;
    }
    const response: ISearchResult = await axios.get(searchQuery);
    return response;
  } catch (err) {
    const { response } = err;
    throw response;
  }
};
