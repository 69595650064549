import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";
import LandingPage from "../pages/landing-page";
import AlertsPage from "../pages/alerts-page";
import Results from "../pages/result";
import LoginPage from "../pages/login";
import RegisterPage from "../pages/register";
import ForgetPasswordPage from "../pages/forget-password";
import ResetPasswordPage from "../pages/reset-password";
import ConfigurationPage from "../pages/configuration-page";
import CustomFieldsPage from "../pages/custom-fields-configuration-page";
import CustomFields from "../pages/custom-fields";
import InvitationPage from "../pages/invite";
import DataSourceFieldPage from "../pages/data-source-field";
import ProfilePage from "../pages/profile-page";
import AccountPage from "../pages/accounts";
import PrivateRoute from '../routes/private-route';
import PublicRoute from '../routes/public-route';
import ConfigurationRoute from '../routes/configuration-route';
import CsvImport from '../pages/csv-import';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 50, beforeChildren: true },
  exit: { opacity: 0, beforeChildren: false }
});

const DefaultContainer = ({ location }: any) => {
  return (
    <PoseGroup>
      <RouteContainer className="route-conatiner" key={location.pathname}>
        <Switch location={location}>
          <PrivateRoute path="/" exact={true}>
            <LandingPage />
          </PrivateRoute>
          <PrivateRoute path="/alerts" exact={true}>
            <AlertsPage />
          </PrivateRoute>
          <PrivateRoute path="/results" exact={true}>
            <Results />
          </PrivateRoute>
          <PrivateRoute path="/profile" exact={true}>
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute path="/invite" exact={true}>
            <InvitationPage />
          </PrivateRoute>
          <PublicRoute path="/register" exact={true}>
            <RegisterPage />
          </PublicRoute>
          <ConfigurationRoute path="/customfields" exact={true}>
            <CustomFieldsPage />
          </ConfigurationRoute>
          <PrivateRoute path="/custom_fields" exact={true}>
            <CustomFields />
          </PrivateRoute>
          <PrivateRoute path="/csv_import" exact={true}>
            <CsvImport />
          </PrivateRoute>
          <ConfigurationRoute path="/setup" exact={true}>
            <ConfigurationPage />
          </ConfigurationRoute>
          <ConfigurationRoute path="/account" exact={true}>
            <AccountPage />
          </ConfigurationRoute>
          <ConfigurationRoute path="/datasource" exact={true}>
            <DataSourceFieldPage />
          </ConfigurationRoute>
          <PublicRoute path="/login" exact={true}>
            <LoginPage />
          </PublicRoute>
          <PublicRoute path="/forgetPassword" exact={true}>
            <ForgetPasswordPage />
          </PublicRoute>
          <Route exact={true} path="/resetPassword">
            <ResetPasswordPage />
          </Route>
          <PublicRoute path="*" exact={true}>
            <h1>Page Not Found</h1>
          </PublicRoute>
        </Switch>
      </RouteContainer>
    </PoseGroup>
  );
};

export default withRouter(DefaultContainer);
