import { axiosInstance as axios } from "../constants/constants";

export const GetContactKeys = async () => {
  try {
    const response: any = axios.get("/contacts/keys");
    return response;
  } catch (err) {
    const { response } = err;
    throw response;
  }
};
