import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  ConnectToDataSource,
  AuthenticateDataSource
} from "../services/data-source";
import { showToast } from "../constants/constants";
import { configurationUrl } from "../constants/constants";
import DefaultButton from "../components/button.component";
import { getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { RoutesLocationEnum } from "../interfaces/enum";

const ConfigurationPage = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let query = useLocation();

  // function to authenticate salesforce token
  const authenticate = async () => {
    const search = new URLSearchParams(query.search);
    const token = search.get("code") || "";
    setLoading(true);
    if (token) {
      try {
        const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
        const account = user.accounts[0].id;
        await AuthenticateDataSource({
          callbackUrl: configurationUrl,
          code: token,
          account
        });
        history.push({ pathname: RoutesLocationEnum.DATA_SOURCE_FIELD });
      } catch (err) {
        if (err.statusCode === 503) {
          showToast.error(err.message, { className: "custom-toast" });
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  // function for redirecting to salesforce to get authorization code.
  const connectToSalesForce = async () => {
    try {
      const response = await ConnectToDataSource(configurationUrl);
      setLoading(false);
      window.location.href = response;
    } catch (err) {
      showToast.error(err, { className: "custom-toast" });
    }
  };

  return (
    <div className="container-fluid public-route-container profile-page">
      <div className="row align-items-center h-100">
        <div className="col-12">
          <div className="login-container configuration-container">
            <Form>
              <div className="logo">
                <img
                  src={require("../assets/login-signup-logo.png")}
                  height="40"
                />
              </div>
              <div className="connect-to-salesforce login-btn">
                {!loading ? (
                  <>
                    <DefaultButton
                      title="Connect to Salesforce"
                      style={{ marginBottom: "10px" }}
                      onClick={connectToSalesForce}
                    />
                    <Link className="d-block" to={RoutesLocationEnum.DASHBOARD}>Do this later</Link>
                  </>
                ) : (
                    <div className="configuration-spinner">
                      <Spinner animation="grow" />
                    </div>
                  )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationPage;
