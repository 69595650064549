import React, { useState, useEffect } from "react";
import { Spinner, Table, Row, Col, ProgressBar } from "react-bootstrap";
import { GetCustomKeys } from "src/services/data-source";
import DefaultButton from "../components/button.component";
import { AddCSVMapping } from "../services/csv-mapping";
import { showToast } from "../constants/constants";
import { cloneDeep } from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { RoutesLocationEnum } from "../interfaces/enum";
import { axiosInstance as axios } from "../constants/constants";
import { ICustomField } from "../interfaces/custom_fields/custom-fields";
import { IProgress } from "../interfaces/progress/progress";
import { ICsvMapping } from "../interfaces/csv_mapping/csv_mapping";

const CsvImport = () => {
  // state initialize
  const [loading, setLoading] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(true);
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [defaultAlkemyFields, setDefaultAlkemyFields] = useState<ICsvMapping[]>([]);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  // --

  const location = useLocation();

  const history = useHistory();

  const getCustomKeyFields = async () => {
    const { state }: any = location;
    if (state) {
      setLoading(true);
      let customKeysResponse = await GetCustomKeys();
      customKeysResponse = customKeysResponse.map((customKey: ICustomField) => {
        return {
          primaryFieldName: customKey.label,
          customKeyId: customKey.id,
          mappedKey: "",
        };
      });

      setOverlayLoading(false);
      setDefaultAlkemyFields([
        {
          primaryFieldName: "firstName",
          mappedKey: "",
        },
        {
          primaryFieldName: "lastName",
          mappedKey: "",
        },
        {
          primaryFieldName: "title",
          mappedKey: "",
        },
        {
          primaryFieldName: "email",
          mappedKey: "",
        },
        ...customKeysResponse,
      ]);
      setCsvHeaders(state.csvHeaders);
      setLoading(false);
    } else {
      history.replace(RoutesLocationEnum.DASHBOARD);
    }
  };

  useEffect(() => {
    getCustomKeyFields();
  }, []);

  // function to get form value of mapped key
  const getMappedKey = (mappedKey: string, index: number) => {
    const duplicateDefaultFields = [...defaultAlkemyFields];
    duplicateDefaultFields[index].mappedKey = mappedKey;
    setDefaultAlkemyFields(duplicateDefaultFields);
  };

  // request to server for adding csv mapping
  const addCSVMapping = async () => {
    setOverlayLoading(true);
    try {
      const { state }: any = location;
      let duplicateDefaultAlkemyFields = cloneDeep(defaultAlkemyFields);
      duplicateDefaultAlkemyFields = duplicateDefaultAlkemyFields.map(
        (field: any, index: number) => {
          if (field.customKeyId) {
            delete field.primaryFieldName;
          }
          return field;
        }
      );
      const file: FormData = new FormData();
      file.append("file", state.file);

      const fileUploadResponse: any = await axios.post("/csv/upload", file, {
        onUploadProgress: (progress: IProgress) => {
          let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
          if (percentCompleted <= 99) {
            setFileUploadProgress(percentCompleted);
          }
        },
      });
      setFileUploadProgress(100);

      const csvMappingPayload = {
        mappings: defaultAlkemyFields,
        fileId: fileUploadResponse.fileId,
      };
      await AddCSVMapping(csvMappingPayload);
      showToast.success("Csv has bee saved successfully and it should be ready in 20 minutes", {
        className: "custom-toast",
      });
      setTimeout(() => {
        history.replace(RoutesLocationEnum.DASHBOARD);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
    setOverlayLoading(false);
  };

  const capitalize = (s: string) => {
    return s.toLowerCase().replace(/\b./g, a => a.toUpperCase());
  };

  return (
    <div className="customfields-page">
      {overlayLoading && (
        <div className="centeredOverlay">
          <Spinner animation="grow" />
        </div>
      )}
      <Row>
        <Col xs="12">
          {!loading ? (
            <React.Fragment>
              <h5 className="main-heading">Csv Mapping</h5>
              <Table bordered={true}>
                <thead>
                  <tr>
                    <th>
                      <span>Alkemy Fields</span>
                    </th>
                    <th>
                      <span>CSV Field</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {defaultAlkemyFields.map(
                    (defaultAlkemyField: ICsvMapping, defaultAlkemyFieldsIndex: number) => {
                      return (
                        <tr key={defaultAlkemyFieldsIndex}>
                          <td>
                            {defaultAlkemyField.primaryFieldName
                              ? capitalize(defaultAlkemyField.primaryFieldName.replace("_", " "))
                              : ""}
                          </td>
                          <td>
                            <select
                              className="index-dropdown"
                              onChange={(ev: any) =>
                                getMappedKey(ev.target.value, defaultAlkemyFieldsIndex)
                              }
                            >
                              <option value="select-index">Select CSV Header</option>
                              {csvHeaders.map((csvHeader: string, csvHeaderIndex: number) => {
                                return (
                                  <option key={csvHeaderIndex} value={csvHeader}>
                                    {csvHeader}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          ) : (
            <div className="spinner-container">
              <Spinner animation="grow" />
            </div>
          )}
        </Col>
      </Row>
      {!loading ? (
        <React.Fragment>
          <DefaultButton
            title="Upload"
            onClick={() => {
              addCSVMapping();
            }}
            hideLoader={true}
            // disabled={disableMainBtn}
          />
        </React.Fragment>
      ) : null}
      {fileUploadProgress !== 0 && (
        <div className="progress-bar-container">
          <ProgressBar now={fileUploadProgress} label={`${fileUploadProgress}%`} />
        </div>
      )}
    </div>
  );
};

export default CsvImport;
