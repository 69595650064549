import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISearchBox } from "../interfaces/search-box";
import { faAngleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { RoutesLocationEnum } from "../interfaces/enum";

const SearchBox = ({
  inputGroupClass,
  formControlClass,
  formSearchValue,
  formOnFocus,
  formOnChange,
  showSearchIcon,
  showCloseIcon,
  reset,
  search,
  inputPlaceHolder,
  onBlur,
  formDefaultValue,
  showAll
}: ISearchBox) => {
  return (
    <InputGroup id="search" className={inputGroupClass}>
      <FormControl
        className={formControlClass}
        placeholder={inputPlaceHolder}
        aria-label="Username"
        value={formSearchValue}
        aria-describedby="basic-addon1"
        onFocus={formOnFocus}
        onBlur={onBlur}
        onChange={formOnChange}
        defaultValue={formDefaultValue}
      />
      {showSearchIcon && (
        <React.Fragment>
          {(!showCloseIcon && showAll) && <Link className="show-all-text" to={RoutesLocationEnum.RESULTS}>Show All</Link>}
          <div className="search-icon" onClick={search}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </React.Fragment>
      )}
      {showCloseIcon && (
        <div className="close-icon" onClick={reset}>
          <FontAwesomeIcon icon={faTimes} color="#CCCCCC" />
        </div>
      )}
    </InputGroup>
  );
};

export default SearchBox;
