import React, { useEffect, useState } from "react";
import DefaultButton from "../components/button.component";
import MUIDataTable from "mui-datatables";
import { GetAlertSummaries } from "../services/alert-summary";
import { Spinner } from "react-bootstrap";
import {
  IAlertSummary,
  IAlertSummaryTable
} from "../interfaces/alert-summary/alert-summary";

const AlertSummary = ({ backToAlerts, alertID }: IAlertSummaryTable) => {
  const [showRecords, setRecords] = useState<IAlertSummary[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: "0",
    total: 0,
    rowsPerPage: 10
  });

  const paginate = (current: number, rowsPerPage: number) => {
    getAlertSummaries(current.toString(), rowsPerPage.toString());
  };

  const getAlertSummaries = async (page: string, rowsPerPage: string) => {
    try {
      const response = await GetAlertSummaries(alertID, page, rowsPerPage);
      const { alertSummaries, total, currentPage, limit } = response;
      setLoading(false);
      setPagination({ total, currentPage, rowsPerPage: parseInt(limit, 10) });
      setRecords(alertSummaries);
    } catch (err) {
      setLoading(false);
      console.log(err, "---er--");
    }
  };

  useEffect(() => {
    setLoading(true);
    getAlertSummaries("0", pagination.rowsPerPage.toString());
  }, []);

  const tableOptions: object = {
    serverSide: true,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 30, 100],
    count: pagination.total,
    page: parseInt(pagination.currentPage, 10),
    onTableChange: (action: string, tableState: any) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case "changePage":
          paginate(tableState.page, pagination.rowsPerPage);
          break;
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      paginate(parseInt(pagination.currentPage, 10), numberOfRows);
    },
    rowHover: false,
    disableToolbarSelect: true
  };

  const columns = [
    {
      name: "broadcastMedium",
      label: "Medium",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (cellValue: string) => {
          let dateOptions = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric"
          };
          let createdAt = new Date(cellValue);
          return (
            createdAt.toLocaleDateString("en-US", dateOptions) +
            ", " +
            createdAt.toLocaleTimeString()
          );
        }
      }
    }
  ];

  return (
    <div>
      <div className="result-table alert-summary-table">
        {!loading ? (
          <MUIDataTable
            data={showRecords}
            columns={columns}
            options={{
              ...tableOptions,
              selectableRowsHeader: showRecords.length ? true : false,
              selectableRows: "none"
            }}
            title=""
          />
        ) : (
          <div className="spinner-container">
            <Spinner animation="grow" />
          </div>
        )}
      </div>
      <DefaultButton
        title="Back"
        style={{ width: "140px" }}
        onClick={backToAlerts}
      />
    </div>
  );
};

export default AlertSummary;
