import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faBell, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  removeLocalStorageItem,
  getAccountID,
  getLocalStorageItem,
} from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import history from "../history/history";
import { RoutesLocationEnum } from "../interfaces/enum";

export const colors = ["#4E5559", "#4E5559", "#a280ff", "#e56399"];

export const bgColors = ["#4E5559", "#e56399", "#a280ff", "#e56399"];

export const icons = [faBell, faBell, faCheck, faTimes];

export const configurationUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/setup"
    : "https://cocky-cori-98d9cf.netlify.com/setup";

export const slackRedirectUri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/profile"
    : "https://cocky-cori-98d9cf.netlify.com/profile";

// const baseURL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3000"
//     : "https://alkemy-staging.herokuapp.com";

const baseURL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use((config: any) => {
  // request urls that should contain account id in header
  config.headers.Account = getAccountID();

  // add authorization token
  const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { response } = error;
    if (response && response.status === 401) {
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
      history.push(RoutesLocationEnum.LOGIN);
    }
    return Promise.reject(error);
  }
);
toast.configure();
export const showToast = toast;
