import React from "react";
import IAlertIcon from '../interfaces/alert-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertIcon = ({ icon, color, bgColor }: IAlertIcon) => {
    return (
        <div className='alert-icon' style={{backgroundColor: bgColor}}>
            <FontAwesomeIcon icon={icon} color={color}/>
        </div>
    );
};

export default AlertIcon;
