import React from "react";

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore
interface IContextProps {
  records_length: number;
  setRecordLength: any;
  setResponseTime: any;
  children: JSX.Element[];
  response_time: number;
}

const SearchResultContext = React.createContext<Partial<IContextProps>>({});

export default SearchResultContext;
