import { axiosInstance as axios } from "../constants/constants";

export const ConnectSlack = async (slackAuthCode: string) => {
  try {
    const response: any = await axios.post("/slack/authenticate", {
      code: slackAuthCode
    });
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const RemoveSlack = async () => {
  try {
    const response: any = await axios.delete("/slack");
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};
