export enum AlertEnum {
  UNREAD = 1,
  READ = 2,
  DELETE = 3
}

export enum RoleEnum {
  OWNER = "Owner",
  ADMIN = "Admin",
  COLLABORATOR = "Collaborator"
}

export enum ConditionNameTypeEnum {
  SELECT_PROPERTY = "select-property",
  SELECT_OPERATOR = "select-operator"
}

export enum CustomFieldDefaultIndexEnum {
  SELECT_INDEX = "select-index"
}

export enum SortDirectionEnum {
  ASCENDING = "ascending",
  DESCENDING = "descending"
}

export enum RoutesLocationEnum {
  ACCOUNT = "/account",
  SETUP = "/setup",
  LOGIN = '/login',
  DASHBOARD = '/',
  CUSTOM_FIELDS = '/customfields',
  CUSTOM_FIELDS_SETUP = '/custom_fields',
  RESULTS = '/results',
  ALERTS = '/alerts',
  PROFILE = '/profile',
  INVITE = '/invite',
  DATA_SOURCE_FIELD = '/datasource',
  CSV_IMPORT = '/csv_import'
}
