import { axiosInstance as axios } from "../constants/constants";

export const GetAlertSummaries = async (alertID: string, page: string, rowsPerPage: string) => {
  try {
    const response: any = await axios.get(
      `/alert-summaries?alert=${alertID}&page=${page}&limit=${rowsPerPage}`
    );
    return response;
  } catch (err) {
    const { response } = err;
    throw response;
  }
};
