import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showToast } from "../constants/constants";
import DefaultButton from "../components/button.component";
import { getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { RoutesLocationEnum } from "../interfaces/enum";
import { AddDataSourceType } from "../services/data-source";
import IUser from "../interfaces/user";

const DataSourceFieldPage = () => {
  const [loading, setLoading] = useState(false);
  const [dataSourceType, setDataSourceType] = useState("");
  const history = useHistory();

  const addDataSourceField = async () => {
    try {
      if (dataSourceType && dataSourceType !== "select-data-source-type") {
        let user: IUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
        let dataSources = user.accounts[0].dataSources || [];

        setLoading(true);
        await AddDataSourceType(dataSources[0].id, dataSourceType);
        showToast.success("Data Source has been added successfully", {
          className: "custom-toast"
        });
        setTimeout(() => {
          history.push({
            pathname: RoutesLocationEnum.CUSTOM_FIELDS,
            state: { register: true }
          });
        }, 3000);
      }
      // request to server for setting data source account
    } catch (err) {
      setLoading(false);
      console.log("err:", err);
    }
  };
  return (
    <div className="container-fluid public-route-container profile-page">
      <div className="row align-items-center h-100">
        <div className="col-12">
          <div className="login-container configuration-container">
            <Form>
              <div className="logo">
                <img
                  src={require("../assets/login-signup-logo.png")}
                  height="40"
                />
              </div>

              <Form.Group controlId="formBasicDataSourceField">
                <p className="instructions text-center">
                  Which record type do you want to send from Salesforce to Alkemy?
                </p>
                <select
                  className="property-dropdown toggle-alert-status"
                  value={dataSourceType}
                  onChange={e => setDataSourceType(e.target.value)}
                >
                  <option value="select-data-source-type">
                    Select Record Type
                  </option>
                  <option value="Contact">Contacts</option>
                  <option value="Lead">Leads</option>
                </select>
              </Form.Group>
              <div className="login-btn">
                <DefaultButton
                  title="Save"
                  onClick={addDataSourceField}
                  style={{ marginBottom: "10px" }}
                  disabled={loading}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSourceFieldPage;
