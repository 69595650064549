import React, { useState, useEffect } from "react";
import { IInviteUser, IInvitedUsers } from "../interfaces/invite/invite";
import { IAccountMembers } from "../interfaces/account_members/account-members";
import {
  GetRoles,
  InviteUser,
  GetInvitedUsers,
  RemoveInvitedUser
} from "../services/invite";
import { RemoveAccountMember } from "../services/account-member";
import { GetUserAccountMembers } from "../services/users";
import { showToast } from "src/constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, Spinner, Table, Row, Col, Modal } from "react-bootstrap";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { getLocalStorageItem, setLocalStorageItem } from "../utilis/helper";
import { useHistory } from "react-router-dom";
import { RoleEnum, RoutesLocationEnum } from "../interfaces/enum";
import DefaultInput from "../components/input-field.component";
import DefaultButton from "../components/button.component";
import IRoles from "../interfaces/roles/roles";
import DefaultOutlineButton from "../components/default-outline-button.component";
import IAccount from "../interfaces/accounts/accounts";

const InvitationPage = () => {
  // state initialize
  const [user, setUser] = useState<IInviteUser>({
    email: "",
    role: ""
  });
  const [roles, setRoles] = useState<IRoles[]>([]);
  const [users, setUsers] = useState<IInvitedUsers[]>([]);
  const [accountMembers, setAccountMembers] = useState<IAccountMembers[]>([]);
  const [loading, setLoading] = useState(true);
  const [modal, showModal] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [activeUserRole, setActiveUserRole] = useState<any>(null);
  const [modalShow, setModalShow] = useState<any>(null);
  // ---

  const history = useHistory();

  // function to invite user
  const inviteUser = async (ev: any) => {
    ev.preventDefault();
    try {
      setOverlayLoading(true);
      const payload = {
        ...user
      };
      resetForm();
      const invitationResponse = await InviteUser(payload);
      if (invitationResponse.description) {
        showToast.success("User has been added successfully", {
          className: "custom-toast"
        });
      } else {
        showToast.success("User has been invited successfully", {
          className: "custom-toast"
        });
      }
      await getAccountMembers();
      setOverlayLoading(false);
    } catch (err) {
      setOverlayLoading(false);
      if (err.statusCode === 409 && err.message === "Invitation already sent") {
        showToast.error("Invitation has already been sent", {
          className: "custom-toast"
        });
      } else if (err.statusCode === 409) {
        showToast.error("User is already registered", {
          className: "custom-toast"
        });
      } else {
        showToast.error(err.error, {
          className: "custom-toast"
        });
      }
    }
  };

  // function to reset modal form
  const resetForm = () => {
    setUser({ email: "", role: "" });
    showModal(false);
  };

  // function to remove account member
  const removeAccountMember = async (accountMember: IAccountMembers) => {
    try {
      setOverlayLoading(true);
      await RemoveAccountMember(accountMember.id);
      const currentLoggedInUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
      if (currentLoggedInUser.id === accountMember.user.id) {
        const currentLoggedInUserAccount = currentLoggedInUser.accounts.filter(
          (account: IAccount) => {
            return account.id !== accountMember.account.id;
          }
        );
        currentLoggedInUser.accounts = currentLoggedInUserAccount;
        setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, currentLoggedInUser);
        history.push(RoutesLocationEnum.ACCOUNT);
      } else {
        await getAccountMembers();
        await getInvitedUsers();
        setModalShow(null);
        setOverlayLoading(false);
      }
    } catch (err) {
      setOverlayLoading(false);
      console.log(err, "--er");
    }
  };

  const getAccountMembers = async () => {
    try {
      const response = await GetUserAccountMembers();
      setAccountMembers(response);
      const currentLoggedInUserId = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER)
        .id;
      const currentLoggedInUserRole = response.find(
        (account: IAccountMembers) => account.user.id === currentLoggedInUserId
      );
      setActiveUserRole(currentLoggedInUserRole);
      if (currentLoggedInUserRole.role.name !== RoleEnum.COLLABORATOR) {
        // function to get invited users.
        getInvitedUsers();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "--er");
    }
  };

  const getInvitedUsers = async () => {
    try {
      const response = await GetInvitedUsers();
      setUsers(response.invitations);
      setLoading(false);
    } catch (err) {
      console.log(err, "--er");
    }
  };

  useEffect(() => {
    // function to get roles
    const getRoles = async () => {
      try {
        const response = await GetRoles();
        setRoles(response);
      } catch (err) {
        console.log(err, "--er");
      }
    };

    // function to get account members.
    getAccountMembers();
    getRoles();
  }, []);

  // UI for rendering remove account member icon on basis of role
  const showRemoveButton = (accountMember: IAccountMembers) => {
    if (
      (activeUserRole.role.name === RoleEnum.OWNER ||
        activeUserRole.role.name === RoleEnum.ADMIN) &&
      accountMember.role.name !== RoleEnum.OWNER
    ) {
      return (
        <span onClick={() => openWarningAlert("account-member", accountMember)}>
          <FontAwesomeIcon icon={faTrash} color="white" />
        </span>
      );
    } else if (
      activeUserRole.role.name === RoleEnum.COLLABORATOR &&
      activeUserRole.id === accountMember.id
    ) {
      return (
        <span onClick={() => openWarningAlert("account-member", accountMember)}>
          <FontAwesomeIcon icon={faTrash} color="white" />
        </span>
      );
    }
  };

  // request to server to remove invited user
  const removeInvitationMember = async (invitedUser: IInvitedUsers) => {
    try {
      setOverlayLoading(true);
      await RemoveInvitedUser(invitedUser.id);
      await getInvitedUsers();
      setOverlayLoading(false);
      setModalShow(null);
    } catch (err) {
      setOverlayLoading(false);
      console.log(err, "--er");
    }
  };

  // UI for rendering remove invitation icon
  const showInvitationRemoveButton = (invitedUser: IInvitedUsers) => {
    return (
      <span onClick={() => openWarningAlert("invitation", invitedUser)}>
        <FontAwesomeIcon icon={faTrash} color="white" />
      </span>
    );
  };

  const openWarningAlert = (
    type: string,
    entityToDelete: IAccountMembers | IInvitedUsers
  ) => {
    entityToDelete.type = type;
    setModalShow(entityToDelete);
  };

  return (
    <div className="customfields-page">
      <Modal
        show={modalShow !== null}
        centered={true}
        onHide={() => setModalShow({})}
      >
        <Modal.Header>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
        <Modal.Footer>
          <DefaultButton
            title={"Yes"}
            onClick={() => modalShow.type === "account-member"
            ? removeAccountMember(modalShow)
            : removeInvitationMember(modalShow)}
            style={{ width: "100px" }}
            hideLoader={true}
          />
          <DefaultOutlineButton
            title="No"
            onClick={() => setModalShow(null)}
            style={{ width: "100px" }}
          />
        </Modal.Footer>
      </Modal>
      {overlayLoading && (
        <div className="centeredOverlay">
          <Spinner animation="grow" />
        </div>
      )}
      <Modal
        dialogClassName="modal-60w"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        show={modal}
        onHide={() => resetForm()}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="contained-modal-title-vcenter">
            Invite User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={inviteUser}>
            <Form.Group controlId="formBasicFirstName">
              <DefaultInput
                placeHolder="User Email"
                type="email"
                value={user.email}
                onChange={ev => setUser({ ...user, email: ev.target.value })}
                required={true}
              />
              <Form.Control.Feedback type="invalid">
                Please enter user email.
              </Form.Control.Feedback>
            </Form.Group>
            <select
              className="index-dropdown select-role-dropdown"
              onChange={ev => setUser({ ...user, role: ev.target.value })}
              value={user.role}
            >
              <option value="select-role">Select Role</option>
              {roles.map((role: IRoles, index: number) => (
                <option value={role.id} key={index}>
                  {role.name}
                </option>
              ))}
            </select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton
            title={"Invite"}
            onClick={ev => inviteUser(ev)}
            style={{ width: "100px" }}
            disabled={!user.email || !user.role}
            hideLoader={true}
          />
          <DefaultOutlineButton
            title="Cancel"
            onClick={() => resetForm()}
            style={{ width: "100px" }}
          />
        </Modal.Footer>
      </Modal>
      {overlayLoading && (
        <div className="centeredOverlay">
          <Spinner animation="grow" />
        </div>
      )}
      <h5 className="main-heading">Account Members</h5>

      <Row>
        <Col xs="12">
          {!loading ? (
            <Table bordered={true}>
              <thead>
                <tr>
                  <td>Email</td>
                  <td>Role</td>
                  <td>Status</td>
                  <td className="center-field-text">
                    {activeUserRole.role.name !== "Collaborator" && (
                      <span onClick={() => showModal(true)}>
                        <FontAwesomeIcon icon={faPlus} color="white" />
                      </span>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {users.map((invitedUser: IInvitedUsers, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="customFieldNameCell">
                        {invitedUser.email}
                      </td>
                      <td className="customFieldNameCell">
                        {invitedUser.role.name}
                      </td>
                      <td className="customFieldNameCell">
                        {invitedUser.hasAccepted ? "Accepted" : "Not Accepted"}
                      </td>
                      <td className="center-field-text">
                        {showInvitationRemoveButton(invitedUser)}
                      </td>
                    </tr>
                  );
                })}

                {accountMembers.map(
                  (accountMember: IAccountMembers, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="customFieldNameCell">
                          {accountMember.user.email}
                        </td>
                        <td className="customFieldNameCell">
                          {accountMember.role.name}
                        </td>
                        <td className="customFieldNameCell">Registered</td>
                        <td className="center-field-text">
                          {activeUserRole && showRemoveButton(accountMember)}
                        </td>
                      </tr>
                    );
                  }
                )}
                {!users.length && !accountMembers.length ? (
                  <tr>
                    <td colSpan={4} className="no-field-found">
                      No User Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          ) : (
            <div className="spinner-container">
              <Spinner animation="grow" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InvitationPage;
