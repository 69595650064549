import { axiosInstance as axios } from "../constants/constants";
import { setLocalStorageItem, getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import IUser from "../interfaces/user";
import IDataSource from "../interfaces/data_source/data-source";

export const ConnectToDataSource = async (callBackUrl: string) => {
  try {
    const response: any = await axios.get(
      `/salesforce/auth-url?callbackUrl=${callBackUrl}`
    );
    const { url } = response;
    return url;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const AuthenticateDataSource = async (payload: IDataSource) => {
  try {
    const response: any = await axios.post("/salesforce/authenticate", payload);
    delete response.account;
    let user: IUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    const dataSources = user.accounts[0].dataSources || [];
    dataSources.push(response);
    user.accounts[0].dataSources = dataSources;
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const CompleteSetup = async (id: string) => {
  try {
    const response: any = await axios.post(`/data-sources/${id}/complete-setup`);
    delete response.account;
    let user: IUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    user.accounts[0].dataSources[0] = response;
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const RemoveDataSource = async (id: string, removeContacts: boolean) => {
  try {
    const response: any = await axios.post(`/data-sources/${id}/unlink`, { removeContacts });
    let user: IUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    let dataSources = user.accounts[0].dataSources || [];
    dataSources = dataSources.filter((dataSource: any) => {
      return dataSource.id !== id;
    });
    user.accounts[0].dataSources = dataSources;
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const AddDataSourceType = async (id: string, type: string) => {
  try {
    const response: any = await axios.patch(`/data-sources/${id}`, {
      dataSourceType: type
    });
    delete response.account;
    let user: IUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER);
    const dataSources = user.accounts[0].dataSources || [];
    dataSources[0] = response;
    user.accounts[0].dataSources = dataSources;
    setLocalStorageItem(LOCAL_STORAGE_KEYS.USER, user);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const GetDataSourceKeys = async (datasource: string) => {
  try {
    const response: any = await axios.get(
      `/salesforce/keys?datasource=${datasource}`
    );
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};

export const GetCustomKeys = async () => {
  try {
    const response: any = await axios.get("/custom-field");
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};


export const GetCustomFields = async () => {
  try {
    const response: any = await axios.get("/custom-field");
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};