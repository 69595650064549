import React, { useState } from "react";
import { IExport } from "../interfaces/button";

const ExportButton = ({ children }: IExport) => {
  const [exportIcon, setExportIcon] = useState(false);
  return (
    <div
      className={`export-button  
        ${exportIcon ? 'active-export-button' : ''}
        `}
      onClick={() => setExportIcon(!exportIcon)}
    >
      <span>Export</span>
      <React.Fragment>
        <div className='icon-group'>{children}</div>
      </React.Fragment>
    </div>
  );
};

export default ExportButton;
