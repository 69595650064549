import React, { useState, useEffect } from 'react';
import {
    Form,
    Spinner,
    Table,
    Row,
    Col,
    Tooltip,
    OverlayTrigger
} from 'react-bootstrap';
import {
    GetCustomKeys
} from 'src/services/data-source';
import {
    CreateCustomKeys,
    RemoveCustomKeys,
    UpdateCustomKeys,
} from 'src/services/custom-fields';
import { showToast } from '../constants/constants';
import { cloneDeep } from 'lodash';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ICustomField,
} from '../interfaces/custom_fields/custom-fields';
import DefaultButton from '../components/button.component';
import {
    CustomFieldDefaultIndexEnum
} from '../interfaces/enum';

const CustomFields = () => {
    // state initialize
    const [loading, setLoading] = useState(false);
    const [selectedFields, updateSelectedFields] = useState<ICustomField[]>([]);
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableMainBtn, setDisableMainBtn] = useState(false);
    const [overlayLoading, setOverlayLoading] = useState(false);
    let [indexes, updateIndexes] = useState(['1', '2', '3', '4']);
    // --

    // function to get custom fields that are already created
    const getCustomFieldsRecord = async () => {
        try {
            let customKeysResponse = await GetCustomKeys();
            const possibleIndexes = [...indexes];
            customKeysResponse = customKeysResponse.map((field: ICustomField) => {
                const indexAlreadyCreated = possibleIndexes.find(
                    (index: string) => index === field.index
                );
                if (indexAlreadyCreated) {
                    possibleIndexes.splice(
                        possibleIndexes.indexOf(indexAlreadyCreated),
                        1
                    );
                }
                return field;
            });
            
            if (!customKeysResponse.length) {
                setDisableMainBtn(true);
            }
            setLoading(false);
            setOverlayLoading(false);
            updateSelectedFields(customKeysResponse);
        } catch (err) {
            console.log(err, ': error');
        }
    };

    useEffect(() => {
        const getKeys = async () => {
            setLoading(true);
            getCustomFieldsRecord();
        };
        getKeys();
    }, []);

    const getLabelName = (labelName: string, index: number) => {
        const selectedFieldClone = cloneDeep(selectedFields);
        selectedFieldClone[index].label = labelName;
        ensureFieldsNotEmpty(selectedFieldClone);
        updateSelectedFields(selectedFieldClone);
    };

    const getIndex = (availableIndex: string, index: number) => {
        const selectedFieldClone = cloneDeep(selectedFields);
        selectedFieldClone[index].index = availableIndex;
        ensureFieldsNotEmpty(selectedFieldClone);
        updateSelectedFields(selectedFieldClone);
    };

    // ----

    // function to validate newly or updated fields
    const ensureFieldsNotEmpty = (fields: ICustomField[]) => {
        const findInvalidField = fields.filter((value) => {
            return (
                value.label === '' ||
                value.index === null ||
                value.index === CustomFieldDefaultIndexEnum.SELECT_INDEX ||
                value.index === ''
            );
        });
        if (!fields.length) {
            setDisableBtn(false);
            setDisableMainBtn(true);
        } else {
            if (!findInvalidField.length) {
                setDisableBtn(false);
                setDisableMainBtn(false);
            } else {
                setDisableBtn(true);
                setDisableMainBtn(true);
            }
        }
    };

    // function to check duplicate indexes
    const duplicateValidation = () => {
        const possibleIndexes = [...indexes];
        let checkDuplicates: string[] = [];
        selectedFields.forEach((element) => {
            if (possibleIndexes.indexOf(element.index) !== -1) {
                checkDuplicates.push(element.index);
            }
        });
        checkDuplicates = checkDuplicates.filter(
            (value, index, self) => self.indexOf(value) !== index
        );
        return checkDuplicates;
    };

    // request to server for updating or creating custom field
    const createUpdateFieldRequest = async (field: ICustomField) => {
        const payload = {
            name: field.name,
            label: field.label,
            index: field.index,
        };
        const response = !field.id
            ? await CreateCustomKeys(payload)
            : await UpdateCustomKeys(payload, field.id);
        return response;
    };

    // request to server for creating and updating custom fields.
    const createCustomFields = async () => {
        if (duplicateValidation().length === 0) {
            try {
                setOverlayLoading(true);
                await Promise.all(
                    selectedFields.map(async (field: ICustomField) => {
                        const response = await createUpdateFieldRequest(field);
                        return response;
                    })
                ).then(async (updatedRes) => {                    
                    await getCustomFieldsRecord();
                    showToast.success('Custom Fields have been updated successfully', {
                        className: 'custom-toast',
                    });
                });
            } catch (error) {
                console.log(error, '---erra');
            }
        } else {
            showToast.error('Custom field indexes can not be same', {
                className: 'custom-toast',
            });
        }
    };

    // function to add new custom field item.
    const addNewCustomField = () => {
        const selectedFieldsClone = [...selectedFields];
        const newField = {
            name: '',
            label: '',
            index: '',
        };
        selectedFieldsClone.push(newField);
        ensureFieldsNotEmpty(selectedFieldsClone);
        updateSelectedFields(selectedFieldsClone);
    };

    // request to server for deleting custom fields
    const removeCustomFieldRequest = async (value: any, index: number) => {
        const duplicatedFields = cloneDeep(selectedFields);
        const possibleIndexes = [...indexes];
        try {
            setOverlayLoading(true);
            await RemoveCustomKeys(value.id);
            duplicatedFields.splice(index, 1);
            possibleIndexes.push(value.index);
            setOverlayLoading(false);
            // setKeys(dataSourceKeys);
            updateIndexes(possibleIndexes);
            updateSelectedFields(duplicatedFields);
            ensureFieldsNotEmpty(duplicatedFields);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    // function  to remove custom field item and to remove fields that are already created.
    const deleteCustomField = async (value: ICustomField, index: number) => {
        const duplicatedFields = cloneDeep(selectedFields);
        if (value.id) {
            removeCustomFieldRequest(value, index);
        } else {
            duplicatedFields.splice(index, 1);
            updateSelectedFields(duplicatedFields);
            ensureFieldsNotEmpty(duplicatedFields);
        }
    };

    const nameToolTip = (props: any) => {
        return (
            <Tooltip {...props} show={props.show.toString()}>
                Custom Field
            </Tooltip>
        );
    };

    const labelToolTip = (props: any) => {
        return (
            <Tooltip {...props} show={props.show.toString()}>
                Custom label
            </Tooltip>
        );
    };


    return (
        <div className="customfields-page">
            {overlayLoading && (
                <div className="centeredOverlay">
                    <Spinner animation="grow" />
                </div>
            )}
            <Row>
                <Col xs="12">
                    {!loading ? (
                        <React.Fragment>
                            <h5 className="main-heading">Custom Fields</h5>
                            <Table bordered={true}>
                                <thead>
                                    <tr>
                                        <th>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={nameToolTip}
                                            >
                                                <span>Custom Field Index</span>
                                            </OverlayTrigger>
                                        </th>
                                        <th>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={labelToolTip}
                                            >
                                                <span>Custom Field Label</span>
                                            </OverlayTrigger>
                                        </th>
                                        <th className="center-field-text">
                                            <span
                                                onClick={() =>
                                                    selectedFields.length < 4 && !disableBtn
                                                        ? addNewCustomField()
                                                        : null
                                                }
                                                className={
                                                    disableBtn || selectedFields.length >= 4
                                                        ? 'disable-add-field-btn'
                                                        : ''
                                                }
                                            >
                                                <FontAwesomeIcon icon={faPlus} color="white" />
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFields.length ? (
                                        selectedFields.map((field: ICustomField, index) => {
                                            let possibleIndexes = ['1', '2', '3', '4'];
                                            selectedFields.forEach((val) => {
                                                if (val.index && val.index !== field.index) {
                                                    possibleIndexes.splice(
                                                        possibleIndexes.indexOf(val.index),
                                                        1
                                                    );
                                                }
                                            });
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <select
                                                            onChange={(ev) =>
                                                                getIndex(ev.target.value, index)
                                                            }
                                                            value={field.index ? field.index : ''}
                                                            className="index-dropdown"
                                                        >
                                                            <option value="select-index">
                                                                Select Custom Field
                                                        </option>
                                                            {possibleIndexes.map((availableIndex: string) => {
                                                                return (
                                                                    <option
                                                                        key={availableIndex}
                                                                        value={availableIndex}
                                                                    >
                                                                        Custom {availableIndex}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter custom field name"
                                                            className="label-input-customfield"
                                                            value={field.label}
                                                            onChange={(
                                                                ev: React.ChangeEvent<HTMLInputElement>
                                                            ) => getLabelName(ev.target.value, index)}
                                                        />
                                                    </td>
                                                    <td className="center-field-text">
                                                        <span
                                                            onClick={() => deleteCustomField(field, index)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} color="white" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                            <tr>
                                                <td colSpan={4} className="no-field-found">
                                                    No Custom Fields Found
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </Table>
                        </React.Fragment>
                    ) : (
                            <div className="spinner-container">
                                <Spinner animation="grow" />
                            </div>
                        )}
                </Col>
            </Row>
            {!loading ? (
                <React.Fragment>
                    <DefaultButton
                        title="Save"
                        onClick={() => {
                            createCustomFields();
                        }}
                        hideLoader={true}
                        disabled={disableMainBtn}
                    />
                </React.Fragment>
            ) : null}
        </div>
    );
};

export default CustomFields;