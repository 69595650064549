import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import Header from "./components/header.component";
import DefaultContainer from "./container/default-container";
import SearchResultContext from "./store/store";
import history from "./history/history";
function App() {
  // state initialize
  const [splash, showSplash] = useState(true);
  const [splashAnimation, setSplashAnimation] = useState(false);
  const [recordsLength, setRecordLength] = useState(0);
  const [responseTime, setResponseTime] = useState(0);

  useEffect(() => {
    if (splash && !splashAnimation) {
      setTimeout(() => {
        setSplashAnimation(true);
        setTimeout(() => {
          showSplash(false);
        }, 1000);
      }, 1800);
    }
  });
  return splash ? (
    <div className={`landing-page page ${!splash && "landing-page-animation"}`}>
      <div className="content">
        {splash && (
          <div className={`splash ${splashAnimation && "splash-animation"}`}>
            <div className={`splash-image`}>
              <img src={require("./assets/logo-brand4x.png")} />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Router history={history}>
      <SearchResultContext.Provider
        value={{
          records_length: recordsLength,
          setRecordLength,
          response_time: responseTime,
          setResponseTime
        }}
      >
        <React.Fragment>
          <Header />
          <DefaultContainer />
        </React.Fragment>
      </SearchResultContext.Provider>
    </Router>
  );
}

export default App;
