import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { IButton } from "../interfaces/button";

const DefaultButton = ({
  title,
  className,
  onClick,
  style,
  id,
  type,
  disabled,
  hideLoader
}: IButton) => (
  <Button
    className={`primaray-btn ${className}`}
    style={style}
    onClick={onClick}
    id={id}
    type={type === 'submit' ? 'submit' : 'button'}
    disabled={disabled}
  >
    {disabled && !hideLoader ? (
      <Spinner
        animation="border"
        variant="light"
        size="sm"
        className="button-spinner"
      />
    ) : null}
    {title}
  </Button>
);

export default DefaultButton;
