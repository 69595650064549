import { orderBy } from "lodash";
import { SORT_STATUS, LOCAL_STORAGE_KEYS } from "./constants";

export const sort = (type: string, records: any) => {
  SORT_STATUS.KEY = type;
  if (!SORT_STATUS.ACTION || SORT_STATUS.ACTION === 'desc') {
    SORT_STATUS.ACTION = 'asc';
  } else {
    SORT_STATUS.ACTION = 'desc';
  }
  return orderBy(records, type, SORT_STATUS.ACTION as 'asc' | 'desc');
};

export const getAccountID = () => {
  const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER) || {};
  const account = user.accounts || []
  const accountID = account.length ? account[0].id : null;
  return accountID;
};

export const setLocalStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
  return true;
};

export const getLocalStorageItem = (key: string) => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value)
  }
  return null;
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
  return true;
};
