import React, { useState } from "react";
import { showToast } from "../constants/constants";
import { useHistory, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Login } from "../services/auth";
import DefaultInput from "../components/input-field.component";
import DefaultButton from "../components/button.component";
import { RoutesLocationEnum } from "../interfaces/enum";
import { GetSingleAccountMember } from "../services/account-member";

const LoginPage = () => {
  // state intialize
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  // --
  const history = useHistory();

  // function for login request to server
  const login = async (ev: any) => {
    ev.preventDefault();
    const form = ev.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) {
      return false;
    }
    setLoading(true);
    try {
      const user = await Login({ password, email });
      if (user.accounts && user.accounts.length) {
        await GetSingleAccountMember(user.id);
      }
      history.push(RoutesLocationEnum.DASHBOARD);
    } catch (err) {
      setLoading(false);
      showToast.error(err, { className: "custom-toast" });
    }
  };
  return (
    <div className="container-fluid public-route-container">
      <div className="row align-items-center h-100">
        <div className="col-12">
          <div className="login-container">
            <Form onSubmit={login} validated={validated} noValidate={true}>
              <div className="logo">
                <img
                  src={require("../assets/login-signup-logo.png")}
                  height="40"
                />
              </div>
              <Form.Group controlId="formBasicEmail">
                <DefaultInput
                  placeHolder="Email"
                  type="email"
                  value={email}
                  onChange={ev => setEmail(ev.target.value)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <DefaultInput
                  placeHolder="Password"
                  type="password"
                  value={password}
                  onChange={ev => setPassword(ev.target.value)}
                  required={true}
                  minLength={8}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter password of minimum 8 characters.
                </Form.Control.Feedback>
                <div className="forget-password">
                  <Link to="forgetPassword">Forgot Password?</Link>
                </div>
              </Form.Group>
              <div className="login-btn">
                <DefaultButton
                  title="Login"
                  style={{ width: "200px", marginBottom: "10px" }}
                  type="submit"
                  disabled={loading}
                />
                <p className="instructions">
                  Don't have an account?{" "}
                  <Link to="register">Create one here.</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
