import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorageItem } from "../utilis/helper";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { RoutesLocationEnum } from "../interfaces/enum";
import IDataSourcesList from '../interfaces/data_source/data-sources-list';

const redirect = (path: string, location: any) => {
  return (
    <Redirect
      to={{
        pathname: path,
        state: { from: location }
      }}
    />
  );
};

const checkSetupCompletion = (
  dataSource: IDataSourcesList,
  children: any,
  location: any
) => {
  if (dataSource.setup_completed) {
    if (location.pathname === RoutesLocationEnum.CUSTOM_FIELDS) {
      return children;
    } else {
      return redirect(RoutesLocationEnum.DASHBOARD, location);
    }
  } else {
    if (
      location.pathname === RoutesLocationEnum.ACCOUNT ||
      location.pathname === RoutesLocationEnum.DATA_SOURCE_FIELD ||
      location.pathname === RoutesLocationEnum.SETUP
    ) {
      return redirect(RoutesLocationEnum.CUSTOM_FIELDS, location);
    } else {
      return children;
    }
  }
};

const checkDataSourceFieldExist = (
  dataSource: IDataSourcesList,
  children: any,
  location: any
) => {
  if (dataSource.dataSourceType !== null) {
    return checkSetupCompletion(dataSource, children, location);
  } else {
    if (
      location.pathname === RoutesLocationEnum.ACCOUNT ||
      location.pathname === RoutesLocationEnum.CUSTOM_FIELDS ||
      location.pathname === RoutesLocationEnum.SETUP
    ) {
      return redirect(RoutesLocationEnum.DATA_SOURCE_FIELD, location);
    } else {
      return children;
    }
  }
};

const checkDataSourceExist = (
  accounts: any[],
  children: any,
  location: any
) => {
  if (!accounts[0].dataSources || !accounts[0].dataSources.length) {
    if (
      location.pathname === RoutesLocationEnum.ACCOUNT ||
      location.pathname === RoutesLocationEnum.DATA_SOURCE_FIELD ||
      location.pathname === RoutesLocationEnum.CUSTOM_FIELDS
    ) {
      return redirect(RoutesLocationEnum.SETUP, location);
    } else {
      return children;
    }
  } else {
    const dataSources = accounts[0].dataSources;
    const isDataSourceAvailable = dataSources.find((dataSource: IDataSourcesList) => dataSource.name === 'Salesforce');
    if (!isDataSourceAvailable) {
      if (
        location.pathname === RoutesLocationEnum.ACCOUNT ||
        location.pathname === RoutesLocationEnum.DATA_SOURCE_FIELD ||
        location.pathname === RoutesLocationEnum.CUSTOM_FIELDS
      ) {
        return redirect(RoutesLocationEnum.SETUP, location);
      } else {
        return children;
      }
    } else {
      return checkDataSourceFieldExist(
        isDataSourceAvailable,
        children,
        location
      );
    }
  }
};

const checkAccountExist = (accounts: any[], children: any, location: any) => {
  if (accounts.length) {
    return checkDataSourceExist(accounts, children, location);
  } else {
    if (location.pathname === RoutesLocationEnum.ACCOUNT) {
      return children;
    } else {
      return redirect(RoutesLocationEnum.LOGIN, location);
    }
  }
};

const checkToken = (
  token: string,
  accounts: any[],
  children: any,
  location: any
) => {
  if (token) {
    return checkAccountExist(accounts, children, location);
  } else {
    return redirect(RoutesLocationEnum.LOGIN, location);
  }
};

export default function ConfigurationRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN);
        const user = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER) || {};
        const { accounts = [] } = user;
        return checkToken(token, accounts, children, location);
      }}
    />
  );
}
