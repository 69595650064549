import IUpdate from "../interfaces/auth/update-profile";
import { axiosInstance as axios } from "../constants/constants";

export const UpdateProfile = async (payload: IUpdate) => {
    try {
      const response: any = await axios.patch('/users',
        payload
      );
      return response;
    } catch (err) {
      const { response } = err;
      throw response;
    }
  };

  export const GetUserAccountMembers = async () => {
    try {
      const response: any = await axios.get('/users/account-members');
      return response;
    } catch (err) {
      const { response } = err;
      throw response;
    }
  };

  export const GetLoggedInUserDetails = async () => {
    try {
      const response: any = await axios.get('/users/me');
      return response;
    } catch (err) {
      const { response } = err;
      throw response;
    }
  };