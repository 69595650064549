import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { showToast } from "../constants/constants";
import { Register, InvitationRegister } from "../services/auth";
import { useLocation } from "react-router";
import DefaultInput from "../components/input-field.component";
import DefaultButton from "../components/button.component";
import { RoutesLocationEnum } from "../interfaces/enum";

const RegisterPage = () => {
  // state intialize
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [validated, setValidated] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(
    "Please retype password"
  );
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  // ---

  const history = useHistory();

  // function for register request to server
  const register = async (ev: any) => {
    ev.preventDefault();
    const form = ev.currentTarget;
    setValidated(true);

    if (confirmPassword !== password) {
      setPasswordValidation("Passwords don't match");
    }

    if (
      form.checkValidity() === false ||
      confirmPassword !== password
    ) {
      return false;
    }
    setLoading(true);
    if (invitationCode) {
      try {
        await InvitationRegister({
          password,
          lastName,
          firstName,
          phone,
          invitationCode
        });
        showToast.success("Successfully Registered, Please Login", {
          className: "custom-toast"
        });
        setTimeout(() => {
          history.push(RoutesLocationEnum.LOGIN);
        }, 2000);
      } catch (err) {
        setLoading(false);
        showToast.error(err, { className: "custom-toast" });
      }
    } else {
      try {
        await Register({
          password,
          email,
          lastName,
          firstName,
          phone
        });
        history.push(RoutesLocationEnum.DASHBOARD);
      } catch (err) {
        setLoading(false);
        showToast.error(err, { className: "custom-toast" });
      }
    }
  };

  const query = useLocation();

  useEffect(() => {
    // setting invitation code
    const search = new URLSearchParams(query.search);
    const queryString = search.get("code") as string;
    setInvitationCode(queryString);
  }, []);

  return (
    <div className="container-fluid public-route-container">
      <div className="row align-items-center h-100">
        <div className="col-12">
          <div className="login-container">
            <Form onSubmit={register} validated={validated} noValidate={true}>
              <div className="logo">
                <img
                  src={require("../assets/login-signup-logo.png")}
                  height="40"
                />
              </div>
              <Form.Group controlId="formBasicFirstName">
                <DefaultInput
                  placeHolder="First Name"
                  type="name"
                  value={firstName}
                  onChange={ev => setFirstName(ev.target.value)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter first name
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicLastName">
                <DefaultInput
                  placeHolder="Last Name"
                  type="name"
                  value={lastName}
                  onChange={ev => setLastName(ev.target.value)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter last name
                </Form.Control.Feedback>
              </Form.Group>

              {!invitationCode && (
                <Form.Group controlId="formBasicEmail">
                  <DefaultInput
                    placeHolder="Email"
                    type="email"
                    value={email}
                    onChange={ev => setEmail(ev.target.value)}
                    required={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group controlId="formBasicPhone">
                <DefaultInput
                  placeHolder="Phone ( ********** )"
                  type="tel"
                  value={phone}
                  pattern="^\d{10}$"
                  onChange={ev => {
                    setPhone(ev.target.value);
                    setPhoneValidation(false);
                  }}
                  required={true}
                  isInvalid={phoneValidation}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter phone number in format: **********
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <DefaultInput
                  placeHolder="Password"
                  type="password"
                  value={password}
                  onChange={ev => {
                    setPassword(ev.target.value);
                    setPasswordValidation("Please retype password");
                  }}
                  minLength={8}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter password of minimum 8 characters
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword">
                <DefaultInput
                  placeHolder="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={ev => {
                    setConfirmPassword(ev.target.value);
                    setPasswordValidation("Please retype password");
                  }}
                  required={true}
                  isInvalid={passwordValidation === "Passwords don't match"}
                  minLength={8}
                />

                <Form.Control.Feedback type="invalid">
                  {passwordValidation}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="login-btn">
                <DefaultButton
                  title="Register"
                  style={{ width: "200px", marginBottom: "15px" }}
                  type="submit"
                  disabled={loading}
                />
                <p className="instructions">
                  Already have an account? <Link to="login">Sign In</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
