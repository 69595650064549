import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { AlertEnum, RoutesLocationEnum } from "../interfaces/enum";
import { colors, bgColors, icons } from "../constants/constants";
import { useLocation, useHistory } from "react-router-dom";
import { ISearchResult } from "../interfaces/search-result";
import { IRecords } from "../interfaces/records";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIndex } from "lodash";
import { GetContactKeys } from "../services/contacts";
import {
  GetAlertConditions,
  RemoveAlertCondition
} from "../services/alerts";
import { GetSearchRecords } from "../services/results";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { SortDirectionEnum } from "../interfaces/enum";
import MUIDataTable from "mui-datatables";
import IAlerts from "../interfaces/alerts";
import ISorting from "../interfaces/sorting";
import Alert from "../components/alert.component";
import SearchResultContext from "../store/store";
import DefaultButton from "../components/button.component";
import ExportButton from "../components/export-button.component";
import ImportButton from "../components/import-button-component";
import { INewAlert } from "../interfaces/alert";

const Results = () => {
  const [showAlerts, setShowAlerts] = useState<IAlerts[]>([]);
  const [showRecords, setRecords] = useState<IRecords[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [alertCondition, setAlertCondition] = useState<any>(null);
  const [pagination, setPagination] = useState({
    currentPage: "0",
    total: 0,
    rowsPerPage: 10
  });
  const [domainValue, setDomainValue] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    key: "",
    direction: "asc"
  });
  const [loading, setLoading] = useState(false);
  const { setRecordLength, setResponseTime } = useContext(SearchResultContext);
  const [disableAlert, setDisableAlert] = useState(false);

  // method to update alert status on clicking
  const updateAlertStatus = async (type: number, index: number) => {
    setDisableAlert(true);
    if (type === AlertEnum.DELETE) {
      await RemoveAlertCondition(alertCondition[0]);
      await getAlertConditions(domainValue);
    } else if (type === AlertEnum.UNREAD) {
      const defaultAlert: INewAlert = {
        name: `Another user from ${domainValue} signs up`,
        alertConditions: [
          {
            operator: "and",
            type: "contains",
            name: "email",
            text: domainValue
          }
        ],
        alertMediumConfigurations: []
      };
      history.push({ pathname: '/alerts', state: defaultAlert });
    }
  };

  // method to update alert status on hovering
  const updateAlertStatusOnHover = (
    type: number,
    index: number,
    action: string
  ) => {
    let alertsCopy = [...showAlerts];
    if (type === AlertEnum.READ) {
      if (action === "in") {
        alertsCopy[index].type = 3;
      }
    } else if (type === AlertEnum.DELETE) {
      if (action === "out") {
        alertsCopy[index].type = 2;
      }
    }
    setShowAlerts(alertsCopy);
  };

  let query = useLocation();

  const getContacts = async (
    value: string,
    page: string,
    rowsPerPage: string,
    sort?: any
  ) => {
    let customFields: any = [...columns];
    let sortKey = "";
    let domainValueForAlert = "";
    const search = new URLSearchParams(query.search);
    const alertSummaryCode = search.get("alertSummary") || "";
    try {
      if (!sort) {
        // request to fetch table keys on intial rendering
        customFields = await GetContactKeys();
        customFields = customFields.map((field: any, index: number) => {
          return {
            name: field.key,
            label: field.value,
            options: {
              filter: true,
              sort: true,
              customBodyRender: (cellValue: string) => {
                return (
                  <span
                    className={`${field.key === "email" ? "email-cell" : ""}`}
                  >
                    {cellValue ? cellValue : '-'}
                  </span>
                );
              }
            },
            custom: field.custom,
            customKey: field.customKey
          };
        });
      } else {
        // update directions on columns on sorting
        customFields = customFields.map((field: any, index: number) => {
          if (field.name === sort.key) {
            field.options.sortDirection =
              sort.direction === SortDirectionEnum.ASCENDING ? "asc" : "desc";
          } else {
            delete field.options.sortDirection;
          }
          return field;
        });
        const sortedColumnIndex = findIndex(customFields, { name: sort.key });
        sortKey = customFields[sortedColumnIndex].custom
          ? customFields[sortedColumnIndex].customKey
          : sort.key;
      }
      const sortQuery = sort
        ? `&sortBy=${sortKey}&sortDirection=${
        sort.direction === SortDirectionEnum.DESCENDING ? "DESC" : "ASC"
        }`
        : "";

      // request to fetch contacts
      const start = Date.now();
      const response: ISearchResult = await GetSearchRecords(
        value,
        page,
        sortQuery,
        rowsPerPage,
        alertSummaryCode
      );
      const responseTime = (Date.now() - start) / 1000;
      let { contacts, total, currentPage, limit } = response;
      contacts = contacts.map((contactKeys: any) => {
        if (!domainValueForAlert && contactKeys.email) {
          domainValueForAlert = contactKeys.email;
        }
        if (contactKeys.customFields) {
          contactKeys.customFields.forEach((element: any) => {
            contactKeys[element.name] = element.value;
          });
        }
        return contactKeys;
      });

      const domain = domainValueForAlert.substring(
        domainValueForAlert.lastIndexOf("@") + 1
      );
      if (!sort) {
        setResponseTime(responseTime);
      }
      setDomainValue(domain);
      setColumns(customFields);
      setRecordLength(total);
      setPagination({ total, currentPage, rowsPerPage: parseInt(limit, 10) });
      setSorting(sort);
      setRecords(contacts);
      setLoading(false);
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const getAlertConditions = async (searchKeyword: string) => {
    if (searchKeyword) {
      try {
        const response = await GetAlertConditions(searchKeyword);
        const { alertConditions } = response;
        setAlertCondition(response.alertConditions);
        if (alertConditions.length) {
          setShowAlerts([{ type: 2 }]);
        } else {
          setShowAlerts([{ type: 1 }]);
        }
        setDisableAlert(false);
      } catch (err) {
        setDisableAlert(false);
        console.log("err:", err);
      }
    }
  };

  // function for server side pagination
  const paginate = async (current: number, rowsPerPage: number) => {
    const search = new URLSearchParams(query.search);
    const searchKeyword = search.get("search") || "";
    setOverlayLoading(true);
    await getContacts(
      searchKeyword,
      current.toString(),
      rowsPerPage.toString(),
      sorting
    );
    setOverlayLoading(false);
  };

  // function to increase records length on selected value
  const changeRowPerPage = (current: number, rowsPerPage: number) => {
    const search = new URLSearchParams(query.search);
    const searchKeyword = search.get("search") || "";
    setLoading(true);
    getContacts(
      searchKeyword,
      current.toString(),
      rowsPerPage.toString(),
      sorting
    );
  };

  // function for server side sorting
  const sortColumn = async (key: string, direction: string) => {
    const search = new URLSearchParams(query.search);
    const searchKeyword = search.get("search") || "";
    setOverlayLoading(true);
    await getContacts(
      searchKeyword,
      pagination.currentPage,
      pagination.rowsPerPage.toString(),
      { key, direction }
    );
    setOverlayLoading(false);
  };

  // request for initial rendering and when query is updated
  useEffect(() => {
    const search = new URLSearchParams(query.search);
    const searchKeyword = search.get("search") || "";
    setLoading(true);
    getContacts(searchKeyword, "0", pagination.rowsPerPage.toString());
  }, [query]);

  useEffect(() => {
    getAlertConditions(domainValue);
  }, [domainValue]);

  const tableOptions: object = {
    serverSide: true,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 50, 100],
    count: pagination.total,
    page: parseInt(pagination.currentPage, 10),
    onTableChange: (action: string, tableState: any) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case "changePage":
          setOverlayLoading(true);
          paginate(tableState.page, pagination.rowsPerPage);
          break;
      }
    },
    rowsPerPage: pagination.rowsPerPage,
    onChangeRowsPerPage: (numberOfRows: number) => {
      changeRowPerPage(0, numberOfRows);
    },
    rowHover: false,
    disableToolbarSelect: true,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      sortColumn(changedColumn, direction);
    }
  };



  const history = useHistory();

  return (
    <div className="result-page">
      <Row>
        {domainValue && (
          <Col xs="12">
            {alertCondition && (
              <p className="alert-heading">Alert me when...</p>
            )}
            <Row className="alert-row-padding">
              {showAlerts.map((value: IAlerts, index: number) => {
                return (
                  <Alert
                    domainName={domainValue}
                    type={value.type}
                    textColor={colors[value.type]}
                    icon={icons[value.type]}
                    iconBgColor={bgColors[value.type]}
                    key={index}
                    onClick={() =>
                      !disableAlert
                        ? updateAlertStatus(value.type, index)
                        : null
                    }
                    onMouseEnter={() =>
                      updateAlertStatusOnHover(value.type, index, "in")
                    }
                    onMouseOut={() =>
                      updateAlertStatusOnHover(value.type, index, "out")
                    }
                    style={{ marginTop: "10px" }}
                  />
                );
              })}
            </Row>
          </Col>
        )}
        <Col xs="12">
          {!loading ? (
            <div className="result-table">
              {overlayLoading && (
                <div className="centeredOverlay">
                  <Spinner animation="grow" />
                </div>
              )}
              <React.Fragment>
                <MUIDataTable
                  data={showRecords}
                  columns={columns}
                  options={{
                    ...tableOptions,
                    selectableRowsHeader: showRecords.length ? true : false
                  }}
                  title=""
                />
                <Row>
                  <Col md="6" xs="12" sm="12">
                    <DefaultButton
                      className="float-left"
                      title="Add an Alert"
                      onClick={() => history.push(RoutesLocationEnum.ALERTS)}
                    />
                    <ExportButton>
                      <FontAwesomeIcon icon={faSalesforce} color="#1797C0" />
                      <img
                        src={require("../assets/outreach.png")}
                        className="export-icons"
                      />
                      <img
                        src={require("../assets/salesloft.png")}
                        className="export-icons"
                      />
                      <img
                        src={require("../assets/apollo.png")}
                        className="export-icons"
                      />
                    </ExportButton>
                    <ImportButton outline={true} className='ml-3' style={{ width: '140px' }} />
                  </Col>
                </Row>
              </React.Fragment>
            </div>
          ) : (
              <div className="spinner-container">
                <Spinner animation="grow" />
              </div>
            )}
        </Col>
      </Row>
    </div>
  );
};

export default Results;
