import { axiosInstance as axios } from "../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../utilis/constants";
import { setLocalStorageItem } from "../utilis/helper";

export const RemoveAccountMember = async (id: string) => {
  try {
    const response: any = await axios.delete(`/account-members/${id}`);
    return response;
  } catch (err) {
    const { response } = err;
    throw response;
  }
};

export const GetSingleAccountMember = async (userID: string) => {
  try {
    const response: any = await axios.get(`/account-members?user=${userID}`);
    const { role } = response[0];
    setLocalStorageItem(LOCAL_STORAGE_KEYS.ROLE, role);
    return response;
  } catch (err) {
    const { response } = err;
    throw response;
  }
};
