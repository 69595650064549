import { axiosInstance as axios } from "../constants/constants";
import { ICsvMappingPayload } from "../interfaces/csv_mapping/csv_mapping";

export const AddCSVMapping = async (payload: ICsvMappingPayload) => {
  try {
    const response: any = await axios.post("/csv", payload);
    return response;
  } catch (err) {
    const { response } = err;
    const { data: error } = response;
    throw error;
  }
};
